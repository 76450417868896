import {Maybe, UsersUnion} from "../../generated-types";
import {TeamOutlined} from "@ant-design/icons";
import {Avatar, Tooltip} from "antd";
import React from "react";

interface UserAvatarProps {
    avatarUrl?: string | Maybe<string>,
    uu?: UsersUnion | null,
    style?: any,
    className?: string,
}

const UsersUnionsAvatar = (props: UserAvatarProps) => {
    const {uu, avatarUrl, ...otherProps} = props
    if (uu)
        return (<Tooltip key={uu.id} title={uu.title} placement="right" className={props.className}>
            <Avatar src={uu.avatarUrl} icon={<TeamOutlined/>} className={props.className} style={props.style}/>
        </Tooltip>)


    return (<Avatar {...otherProps} src={avatarUrl} icon={<TeamOutlined/>} className={props.className}/>)
}

export default UsersUnionsAvatar