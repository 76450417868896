import React, {type SVGProps} from "react"

export function IconFolder(props: SVGProps<SVGSVGElement>) {
   
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1"
             strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=folder, size=24">
        <g id="Group 27">
            <path id="Vector"
                  d="M19.5599 8.40005V6.96005C19.5599 6.56261 19.2373 6.24005 18.8399 6.24005C18.8399 6.24005 9.96658 6.24005 9.47986 6.24005C8.95102 6.24005 8.58562 4.80005 8.03986 4.80005H4.07986C3.68242 4.80005 3.35986 5.12261 3.35986 5.52005V6.96005V17.4V18.84C3.35986 19.2375 3.68242 19.56 4.07986 19.56H18.1199C18.5173 19.56 18.8399 19.2375 18.8399 18.84"
                  stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round"/>
            <path id="Vector_2"
                  d="M3.35986 18.8401L5.15986 9.12015C5.15986 8.72271 5.48242 8.40015 5.87986 8.40015H19.9199C20.3173 8.40015 20.6399 8.72271 20.6399 9.12015L18.8399 18.8401"
                  stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round"/>
        </g>
    </g>
</svg>

    )
}
