import {Badge, Card, message, Space, Tooltip} from 'antd';
import React, {useState} from 'react';
import {EditTaskInput, Task, useTaskSaveMutation} from "../../generated-types";
import {useTranslation} from 'react-i18next';
import {editTaskContext} from "./TaskEditor";
import Tagger from "../Tagger/Tagger";
import ProjectBreadcrumb from "../ProjectBreadcrumb";
import dayjs from 'dayjs';
import {DragPreviewImage, useDrag, useDrop} from 'react-dnd'
import TaskMembers from "./TaskMembers";
import TaskResolved, {ResolvedCard} from "./TaskResolved";
import TaskTitle from "./TaskTitle";
import {BlockOutlined, FileOutlined, ReadFilled} from "@ant-design/icons";
import {makeVar, ReactiveVar} from "@apollo/client";
import {isMobile} from "react-device-detect";
// import TaskResolvedBlur from "./TaskResolvedBlur";

const dateFormat = 'DD.MM';

const boxImage = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAIGNIUk0AAHomAACAhAAA+gAAAIDoAAB1MAAA6mAAADqYAAAXcJy6UTwAAAAGUExURdva0////yqEAr4AAAABdFJOUwE34ejwAAAAAWJLR0QB/wIt3gAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAAd0SU1FB+cIDhcDNATKr+sAAAAKSURBVAjXY2AAAAACAAHiIbwzAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIzLTA4LTE0VDIzOjAzOjQyKzAwOjAwy5RdQAAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMy0wOC0xNFQyMzowMzo0MiswMDowMLrJ5fwAAAAodEVYdGRhdGU6dGltZXN0YW1wADIwMjMtMDgtMTRUMjM6MDM6NTIrMDA6MDAhdsS9AAAAAElFTkSuQmCC'


interface TasksBoardItemProps {
    task: Task,
    showProjectTitle: boolean
}

export const taskToTaskInput = (task: Task, tags: string[]) => {
    const { title, description, project, members, files, status,startTime, startDate, endTime, endDate, importance } = task
    const res = {
        startTime, startDate, endTime, endDate, importance, description,
        title,
        members: members.map(m => ({userId: m.user.id, memberType: m.memberType})) ?? [],
        files: files.map(f => ({externalFilePath: f.path, name: f.name})) ?? [],
        projectId: project.id,
        tags: tags,
        status
    } as EditTaskInput

    return res
}
export const droppedOnTask: ReactiveVar<Task | null> = makeVar<Task | null>(null);

const TasksBoardItem: React.FC<TasksBoardItemProps> = ({ task, showProjectTitle }) => {
    const { t } = useTranslation();
    const [hovered, setHovered] = useState(false);

    const [{ opacity }, dragRef, preview] = useDrag(
        () => ({
            type: "TASK",
            // type: ItemTypes.CARD,
            item: task,
            collect: (monitor) => ({ opacity: 1, isDragging: monitor.isDragging() })
        }),
        []
    )
    const [taskSave] = useTaskSaveMutation();

    const onTagDelete = (tags: string[]) => {
        taskSave({
            variables: {
                id: task.id,
                task: taskToTaskInput(task, tags)
            },
            onError: (error, clientOptions)=> {
                
                message.error(t('tags.tagDelitionErrorMessage'))
            },
        })
    }

    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'TASK',
        drop: (taskDropped: any, monitor: any) => {
            if (!monitor.didDrop()) {
                droppedOnTask(task)
            }
        },

        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        })
    }))


    const showDates = task.startDate || task.endDate;

    const datesInfo =
        (task.startDate ? dayjs(task.startDate).format(dateFormat) : "")
        + (task.startDate && task.endDate ? "-" : "") +
        (task.endDate ? dayjs(task.endDate).format(dateFormat) : "");

    let gridStyle: React.CSSProperties = ResolvedCard(task.resolved, {
        width: '100%',
        opacity,
        position: "relative",
        padding: 10
    });

    const openTask = (e: any) => {
        editTaskContext({ taskId: task.id });
        e.preventDefault();
        e.stopPropagation();
    }

    const item = <>
        <DragPreviewImage connect={preview} src={boxImage} />
        <div ref={isMobile ? undefined : dragRef}>
            <div ref={isMobile ? undefined : drop} style={{
                borderTop: isOver ? "5px solid #dbdbdb" : undefined,
                opacity: !hovered && task.resolved ? 0.5 : 1
            }}>
                <Card.Grid style={gridStyle} onClick={openTask}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}>

                    <div>
                        {showProjectTitle && <div><ProjectBreadcrumb projectId={task.project.id} /></div>}
                        <TaskResolved
                            id={task.id}
                            value={task.resolved}
                            key={"" + task.resolved}
                            disabled={task.project.archived || task.project.paymentAccount?.isBlocked}
                        />
                        <TaskTitle task={task} />
                    </div>
                    {task.tags && <>
	                    <Tagger key={task.tags.reduce((m, v) => m + v, "")} projectId={task.project.id}
	                            defaultValue={task.tags} onCloseTag={onTagDelete}
	                            allowAdd={false} allowRemove={false}/>
                        <br />
                    </>}
                    {((task.childTasks?.length ?? 0) > 0 || (task.files?.length ?? 0) > 0) && <Space style={{
                        width: `${100}%`,
                        position: 'absolute',
                        right: '5px',
                        bottom: '5px',
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        color: task.childTasks?.length || task.files?.length ? 'gray' : 'lightgray'
                    }} align={'end'}>
                        {task.description && <Tooltip title={task.description}><ReadFilled /></Tooltip>}
                        {task.files && <Tooltip title={t('task.files')}><FileOutlined />{task.files.length}</Tooltip>}
                        {task.childTasks && <Tooltip title={t('task.childTasks')}>
                            <BlockOutlined />{task.childTasks.filter(ct => ct?.resolved).length}/{task.childTasks.length}
                        </Tooltip>
                        }
                    </Space>}
                    {!task.resolved && <div style={{ opacity: hovered ? 1 : 0.5 }}><TaskMembers task={task} /></div>}

                </Card.Grid>
            </div>
        </div></>;


    if (showDates && !task.resolved) {
        let today = dayjs().startOf("day")
        let deadlineOverdue = dayjs(task.endDate).diff(today, "day");

        if (deadlineOverdue == 0)
            return <Tooltip title={t('task.currentTask')}><Badge.Ribbon text={datesInfo} color="orange">{item}</Badge.Ribbon></Tooltip>
        else if (deadlineOverdue < 0)
            return <Tooltip title={t('task.deadlineOverdue')}><Badge.Ribbon text={datesInfo} color="red">{item}</Badge.Ribbon></Tooltip>

        return <Tooltip title={t('task.plannedDate')}><Badge.Ribbon text={datesInfo}
            color="lightgray">{item}</Badge.Ribbon></Tooltip>
    }

    return item;
}

export default TasksBoardItem