import React, {useMemo, useState} from 'react'
import {Button, Col, Empty, Form, message, notification, Row, Space, Watermark} from 'antd'
import {SaveOutlined} from '@ant-design/icons'
import {
    AccessLevel,
    Project,
    usePlugToUnionProjectMutation,
    useProjectAttachTagsMutation,
    useProjectCardQuery,
    useProjectLeaveMutation,
    useProjectSwitchArchivedMutation,
    useProjectUpdateMutation
} from "../../generated-types";
import {useMatch, useNavigate, useParams} from "react-router-dom";
import ProjectMembersEditor from "./ProjectMembersEditor";
import {useTranslation} from "react-i18next";
import ProjectSettings from "./ProjectSettings";
import TaskViewList from "../Tasks/TaskViewList";
import {addTaskContext} from "../Tasks/TaskNew";
import {useReactiveVar} from "@apollo/client";
import {authState} from "../../routes/Auth/authContext";
import ProjectFilesList from "../File/ProjectFilesList";
import {updateProjectInCache} from "../../subscriptions/allProjects";
import Spinner from "../Spinner";
import {isMobile} from "react-device-detect";
import ProjectTransfer from "./ProjectTransfer";
import TooltipButton from "../TooltipButton";
import {editTaskContext} from "../Tasks/TaskEditor";
import ProjectHeader, {ProjectTabs} from "./ProjectHeader";
import {routes} from "../../AppRouter";

const ProjectCard: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const addTaskCtx = useReactiveVar(addTaskContext);
    const [form] = Form.useForm();

    const match = useMatch(routes.projectTask);

    if (match?.params.taskId) {
        editTaskContext({taskId: match.params.taskId})
    }

    const {id, tab} = useParams();
    const [selectedTab, setTab] = useState<ProjectTabs>(ProjectTabs.tasks); // TODO: вернуть получение из параметров
    const [messageApi, contextHolder] = message.useMessage()
    const [taskCount, setTaskCount] = useState(0)

    const {data, loading} = useProjectCardQuery({
        variables: {id: id as string},
        onCompleted: (_) => {
        }
    })

    const authInfo = useReactiveVar(authState);

    const [projectUpdate] = useProjectUpdateMutation({
        update: (cache, r) => {
            updateProjectInCache(cache, r.data?.changedProject as Project)
        },
        onCompleted: () => success()
    });

    const [projectAttachTags] = useProjectAttachTagsMutation({
        update: (cache, r) => {
            updateProjectInCache(cache, r.data?.changedProject as Project)
        }
    });

    const [plugToUnionProject, {}] = usePlugToUnionProjectMutation({
        update: (cache, r) => {
            updateProjectInCache(cache, r.data?.changedProject as Project)
        }
    });

    const [leaveProject, {}] = useProjectLeaveMutation({
        onCompleted: (_) => {
            notification.success({
                message: t("project.leavedSuccessfully"),
                description: project.title,
            });
            navigate("/")
        }
    });

    const [archivedProject] = useProjectSwitchArchivedMutation({
        update: (cache, r) => {
            const project = r.data?.projectSwitchArchived as Project;
            updateProjectInCache(cache, project)
        }
    });

    const success = () => {
        messageApi.open({
            type: 'success',
            content: t('editDataMassage'),
            style: {
                marginTop: '20vh',
            },
        });
    };
    
    const project = data?.project as Project

    let content: any=undefined;

    if(project){
        const isAdmin = project.members.some(m => m.user.id == authInfo.user.id && m.accessLevel == AccessLevel.Admin)
        const isNotMember = !(project.members.some(m => m.user.id == authInfo.user.id))

        addTaskCtx.projectId = project.id;
        addTaskContext(addTaskCtx);

        const save = (input: any) => {
            projectUpdate({variables: {projectId: project.id, input}})
        };

        content = {
            [ProjectTabs.tasks]: <TaskViewList key={project.tasksView.id} tasksView={project.tasksView}
                                               allowCreate={true}
                                               showProjectTitle={false} onTasksFiltered={(_, taskCount) => {
                setTaskCount(taskCount)
            }}/>,
            [ProjectTabs.files]: <ProjectFilesList project={project} isAdmin={isAdmin}/>,
            [ProjectTabs.members]: <ProjectMembersEditor project={project}/>,
            [ProjectTabs.settings]: <>
                <ProjectSettings project={project} form={form} save={save}/>
                <Space direction="vertical" style={{width: '50%', display:'flex', flexDirection:'column'}}>
                    <Button type="primary" block onClick={form.submit} htmlType="submit">
                        {t('project.save')}
                    </Button>
                    <TooltipButton danger ghost block icon={<SaveOutlined />} onClick={()=>archivedProject({
                        variables: {
                            projectSwitchArchivedId: project.id
                        }
                    })} tooltipProps={{title: t('project.archiveTooltip')}} >
                        {project.archived ? t('project.unArchiving') : t('project.archiving')}
                    </TooltipButton>
                    {contextHolder}
                    {isAdmin && <ProjectTransfer project={project} />}
                </Space>
            </>
        }
    }

    const watermarkContent = useMemo(() => {
        if (project && project.paymentAccount?.isBlocked) {
            return t('paymentAccount.projectIsBlocked') as string
        } else if (project && project.archived) {
            return t('project.archived')as string
        } else return undefined
    }, [project])

    if (loading)
        return <Spinner loading={loading}/>

    if (!id || !project) {
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
    }

    // У меня никак не получается положить дропдаун ниже так, чтобы он был справа, а название проекта было во всю оставшуюся ширину.
    return <Watermark content={watermarkContent} style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
        {project && <ProjectHeader project={project} onChangeTab={tab => setTab(tab)} selectedTab={selectedTab}
		                               taskCount={taskCount}/>}

        <Row style={{margin: isMobile ? 0 : "0px 15px 0px 15px", flexGrow: 1}}>
            <Col span={24}>
                {content[selectedTab]}
            </Col>
        </Row>
    </Watermark>
};

export default ProjectCard