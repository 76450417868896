import React from 'react'
import {Button, Spin, theme, Tooltip} from 'antd'
import {Project, useProjectAddToFavoriteMutation} from "../../generated-types";
import {useTranslation} from "react-i18next";
import {updateProjectInCache} from "../../subscriptions/allProjects";
import {IconFavourites} from "../Icon/IconFavourites";

interface ProjectFavoriteButtonProps {
    project: Project
}

const ProjectFavoriteButton = ({project}: ProjectFavoriteButtonProps) => {
    const {t} = useTranslation()
    const {token} = theme.useToken();
    const [addToFavorite, {loading: loadingAdd }] = useProjectAddToFavoriteMutation();

    const isFav = project.UserMarkers && project.UserMarkers.isFavorite;
    const icon = isFav ?
        <IconFavourites color={token.colors.font.accent} fill={token.colors.font.accent}/>
        : <IconFavourites color={token.colors.font.accent}/>;

    return (
        <div className={"project-favorite-button"} style={{minWidth: 32, minHeight: 32}}>
        {loadingAdd
            ? <Spin style={{top: 15}}><div className="content" /></Spin>
            : <Tooltip title={t(isFav ? 'project.RemoveFromFavorite' : 'project.AddToFavorite')}>
                <Button type="text" icon={icon}
                          onClick={(e) => {
                            e.stopPropagation();
                              addToFavorite({variables: {input: {
                                          projectId: project.id,
                                          isFavorite: !isFav
                                      }
                                  },
                                  update: (cache, r) => {
                                    updateProjectInCache(cache, r.data?.projectAddToFavorite as Project)
                              }
                              })
                          }
                          } />
            </Tooltip>
            }
        </div>
    )
}

export default ProjectFavoriteButton