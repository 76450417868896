import {theme} from 'antd'
import {MenuItemType} from 'antd/lib/menu/interface'
import React, {PropsWithChildren} from 'react'
import styled from 'styled-components'

//Кастомный компонент выпадашки для селектов приоритета и доски, вроде еще где-то используется
export interface DropdownMenuItem extends MenuItemType {
    selected: boolean,
}

const SelectMenuDropdown2Styled = styled.div<{}>`
    padding: 16px 0;
`

const DefaultDropdownItem = styled.div<{ selected: boolean, selectedColor: string }>`
    padding: 4px 16px;
    width: 100%;
    position: relative;
    box-sizing: border-box;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 2px;
        background-color: ${({selected, selectedColor}) => selected ? selectedColor : 'unset'};
    }

`

const CustomDropdownElementContainer = ({children, selected}: PropsWithChildren<{ selected: boolean }>) => {
    const {token} = theme.useToken()

    return <DefaultDropdownItem selectedColor={token.colors.ui.accent} selected={selected}>
        {children}
    </DefaultDropdownItem>
}


function SelectMenuDropdown2({items, renderItem, onSelect}: PropsWithChildren<{
    items: DropdownMenuItem[],
    renderItem?: (item: DropdownMenuItem) => React.ReactElement,
    onSelect?: (key: React.Key) => boolean
}>) {
    return (
        <SelectMenuDropdown2Styled className={'select-dropdown'}>
            {items.map(i => {
                return <div key={i.key} className={'dropdown-item'} onClick={() => {
                    if (onSelect) onSelect(i.key);
                }}>
                    {renderItem != undefined
                        ? renderItem(i)
                        : <CustomDropdownElementContainer
                            selected={i.selected}>{i.label}</CustomDropdownElementContainer>}
                </div>
            })}
        </SelectMenuDropdown2Styled>
    )
}

export default SelectMenuDropdown2