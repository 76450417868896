import React, {RefObject, useEffect, useMemo, useRef, useState} from "react"
import {DropdownedStyled} from "./Dropdowned.styled";
import {Drawer, theme} from "antd";
import {isMobile} from "react-device-detect";

export enum CenterJustify {
    start,
    center,
    end
}

export interface DropdownedProps {
    opened: boolean
    title?: string
    onClose: (opened: boolean) => void
    children: React.ReactNode
    anchor?: RefObject<HTMLElement>
    backdrop?: boolean
    popupOnly?: boolean
    centerJustify?: CenterJustify //выравнивание центра попапа по центру экрана и краям/центру якоря
}

const useMousePosition = () => {
    const [
        mousePosition,
        setMousePosition
    ] = React.useState({ x: null, y: null });
    React.useEffect(() => {
        const updateMousePosition = (ev: any) => {
            console.log(ev)
            setMousePosition({ x: ev.screenX, y: ev.screenY });
        };
        window.addEventListener('mousemove', updateMousePosition);
        return () => {
            window.removeEventListener('mousemove', updateMousePosition);
        };
    }, []);
    return mousePosition;
};

let mousePosition: { x: number, y: number } = { x: 0, y: 0 };

window.addEventListener('mousemove', e => mousePosition = { x: e.clientX, y: e.clientY });

/**
 * Отображение выпадающих список
 * @param date
 * @constructor
 */
const Dropdowned: React.FC<DropdownedProps> = ({ children, opened, onClose, title, anchor, backdrop, popupOnly, centerJustify }) => {
    const [open, setOpen] = useState(opened);
    const { token } = theme.useToken();
    const contentRef = useRef<HTMLDivElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const [height, setHeight] = useState<any>(0)
    //const height: any = 0;
    const width: any = 300;

    const justifyed = !(centerJustify === undefined)

    const showDrawer = () => {
        setOpen(true);
    };

    const onCloseEvent = () => {
        setOpen(false);
        onClose(false)
    };
    
    const p = useMemo(() => {

        //Если указан ID элемента-якоря, то пытаемся позиционироваться по нему, если не удается позиционируемся по мыши
        if (anchor) {
            const anchorElement = anchor.current
            
            if (anchorElement) {
                
                const rect = anchorElement.getBoundingClientRect()
                let x, y

                if (!justifyed) {
                    x = rect.x
                    y = rect.y + rect.height + 5
                }

                if (centerJustify === CenterJustify.start) {
                    x = rect.x
                    y = window.innerHeight / 2

                }

                if (centerJustify === CenterJustify.center) {
                    x = rect.x + rect.width / 2
                    y = window.innerHeight / 2
                }

                if (centerJustify === CenterJustify.end) {
                    x = rect.x + rect.width
                    y = window.innerHeight / 2
                }


                return { x, y }
            }
        }

        const x: any = (mousePosition.x ?? 0) - width + 14;
        const y: any = (mousePosition.y ?? 0) + 14;
        return { x, y }
    }, [open]);

    useEffect(() => {
        if (contentRef.current && containerRef.current) {
            if (open) {
                setHeight(contentRef.current.scrollHeight + 'px')

                const onTransitionEnd = () => {
                    setHeight('auto')
                    containerRef.current?.removeEventListener('transitionend', onTransitionEnd)
                }

                containerRef.current.addEventListener('transitionend', onTransitionEnd)
            } else {
                setHeight(0 + 'px')
            }
        }
    }, [open])

    if (isMobile && !popupOnly) {
        return <Drawer
            styles={{
                body: { padding: 0 },
                content: {
                    borderRadius: "16px 16px 0px 0px"
                }
            }}
            title={title}
            placement={"bottom"}
            closable={true}
            onClose={onCloseEvent}
            open={open}
            height={'auto'}

        >
            {children}
        </Drawer>
    }

    return <DropdownedStyled
        className={"dropdowned"}
        $position={p}
        $size={{width, height}}
        $token={token}
        onClick={e => {
            onCloseEvent()
            e.preventDefault()
            e.stopPropagation()
        }}
        ref={containerRef}
        $backdrop={backdrop}
        $justified={justifyed}
    >
        <div className={"content-container"}
            onClick={e => {
                e.preventDefault()
                e.stopPropagation()
            }}

            ref={contentRef}
        >
            {children}
        </div>
    </DropdownedStyled>
};

export default Dropdowned;