
import React, {type SVGProps} from "react"
import {theme} from "antd";

export function IconDragTask(props: SVGProps<SVGSVGElement>) {
   
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1"
             strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=drag task, size=16">
        <g id="Group 17">
            <circle id="Ellipse 12" cx="5.4" cy="2.4" r="1.4" fill="currentColor"/>
            <circle id="Ellipse 13" cx="11.0001" cy="2.4" r="1.4" fill="currentColor"/>
            <circle id="Ellipse 14" cx="11.0001" cy="8.0001" r="1.4" fill="currentColor"/>
            <circle id="Ellipse 15" cx="5.4" cy="8.0001" r="1.4" fill="currentColor"/>
            <circle id="Ellipse 16" cx="5.4" cy="13.6" r="1.4" fill="currentColor"/>
            <circle id="Ellipse 17" cx="11.0001" cy="13.6" r="1.4" fill="currentColor"/>
        </g>
    </g>
</svg>

    )
}
