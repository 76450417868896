
import React, {type SVGProps} from "react"
import {theme} from "antd";

export function IconTag(props: SVGProps<SVGSVGElement>) {
   
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1"
             strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=tag, size=16" height="1em">
        <g id="Frame 417">
            <path id="Vector"
                  d="M1.30663 6.82716L6.8271 1.30669C6.99648 1.13731 7.2711 1.13731 7.44048 1.30669L14.1258 7.99203C14.2284 8.09457 14.2728 8.24155 14.2444 8.38377L13.3243 12.9842C13.29 13.1559 13.1558 13.2901 12.9841 13.3244L8.38372 14.2445C8.24151 14.2729 8.0945 14.2284 7.99197 14.1259L1.30663 7.44054C1.13725 7.27116 1.13723 6.99656 1.30663 6.82716Z"
                  stroke="currentColor"/>
            <circle id="Ellipse 6079" cx="10.3869" cy="10.3974" r="0.722881" fill="currentColor"/>
        </g>
    </g>
</svg>

    )
}
