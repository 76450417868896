import {Maybe, User} from "../../generated-types";
import {UserOutlined} from "@ant-design/icons";
import {Avatar, Tooltip} from "antd";

interface UserAvatarProps {
    avatarUrl?: string | Maybe<string>,
    user?: User | null,
    style?: any,
    className?: string,
    onClickAvatar?: () => void
}

const UserAvatar = (props: UserAvatarProps) => {
    const {user, avatarUrl, onClickAvatar, ...otherProps} = props

    let url = user?.avatarUrl && user?.avatarUrl.indexOf("null") < 0 ? user.avatarUrl : undefined;
    if (avatarUrl && avatarUrl.indexOf("null") < 0)
        url = avatarUrl;

    if (user && url)
        return (<Tooltip key={user.id} title={user.username} placement="top" className={props.className}>
            <Avatar src={url} icon={<UserOutlined/>} className={props.className}
                    style={props.style} onClick={onClickAvatar}/>
        </Tooltip>)


    return (<Avatar {...otherProps} src={url} icon={<UserOutlined/>} className={props.className}
                    onClick={onClickAvatar}
    />)
}

export default UserAvatar