import React, {useState} from "react";
import {Task, TaskStatus, useAddTaskMutation} from "../../generated-types";
import {Button, Input, theme} from "antd";
import {EnterOutlined} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {valuesToTaskInput} from "./TaskCard";
import {AddTaskContext, addTaskContext} from "./TaskNew";
import {useReactiveVar} from "@apollo/client";
import RefetchesList from "../../queries/RefetchesList";
import { IconEnter } from "../Icon/IconEnter";

interface AddTaskInputProps {
    placeholder?: string,
    ctx: AddTaskContext,
    onCompleted?: (task: Task) => void,
    isBlocked?: boolean | undefined
}

const AddTaskInput = ({ctx, placeholder, onCompleted,isBlocked}: AddTaskInputProps) => {
    const {t} = useTranslation();
    const [newTaskFocused, setNewTaskFocused] = useState(false);
    const {token}=theme.useToken();
    const [newTaskInProgress, setNewTaskInProgress] = useState(false);
    const [newTaskTitle, setNewTaskTitle] = useState("");


    const addTaskCtx = useReactiveVar(addTaskContext);

    const [addTask] = useAddTaskMutation({
        onCompleted: (data) => {

            setNewTaskTitle("")
            setNewTaskInProgress(false)
            if (onCompleted)
                onCompleted(data.addTask as Task)
        },
        refetchQueries: [RefetchesList.TasksByViewId, RefetchesList.Task],
    });

    const AddTask = (fastAdd: boolean) => {
        if (newTaskInProgress)
            return

        if (!newTaskTitle || !newTaskTitle.trim())
            return

        if (fastAdd) {
            const task = valuesToTaskInput({
                status: ctx.status,
                title: newTaskTitle
            }, undefined, undefined, undefined);
            task.status = TaskStatus.Todo
            task.projectId = ctx.projectId as string

            setNewTaskInProgress(true)
            addTask({
                variables: {
                    input: {
                        tasksViewColumnId: ctx.tasksViewColumnId,
                        tasksViewId: ctx.taskViewId,
                        parentId: ctx.parentId,
                        task
                    }
                }
            })
        } else {
            addTaskCtx.title = newTaskTitle;
            addTaskCtx.taskViewId = ctx.taskViewId;
            addTaskCtx.tasksViewColumnId = ctx.tasksViewColumnId;
            addTaskCtx.projectId = ctx.projectId;
            addTaskCtx.parentId = ctx.parentId;
            addTaskContext({...addTaskCtx});
            setNewTaskTitle("")
        }
    }

    return <Input disabled={isBlocked} placeholder={placeholder ?? (t('task.addTask') as string + ' (Ctrl+\u21b2)')}
                  variant={newTaskFocused ? "outlined" : "borderless"}
                  onChange={(e) => {
                      if (newTaskInProgress) {
                          e.preventDefault()
                          e.stopPropagation()
                          return;
                      }
                      setNewTaskTitle(e.target.value as any)
                  }}
                  onKeyPress={(e) => {
                      if (e.charCode !== 13)
                          return;
                      AddTask(!e.ctrlKey)
                  }}
                  size="middle"
                  suffix={<Button type={"text"} size={"small"} 
                  icon={<IconEnter style={{
                    fontSize: 16,
                    color: token.colors.font.primary,
                    strokeWidth: 1.5
                }}/>}
                  onClick={(e) => {
                      AddTask(!e.ctrlKey)
                  }}>
                  </Button>}
                  onFocus={() => setNewTaskFocused(true)}
                  onBlur={() => setNewTaskFocused(false)}
                  value={newTaskTitle}
    />
}

export default AddTaskInput