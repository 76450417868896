import React, {type SVGProps} from "react"

export function IconSort(props: SVGProps<SVGSVGElement>) {
   
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1"
             strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=sort, size=24">
        <g id="Frame">
            <path id="Vector" d="M10 14H2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_2" d="M8 10H2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_3" d="M6 6H2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_4" d="M12 18H2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_5" d="M19 20V4M19 20L22 17M19 20L16 17M19 4L22 7M19 4L16 7" stroke="currentColor"
                  strokeLinecap="round" strokeLinejoin="round"/>
        </g>
    </g>
</svg>

    )
}
