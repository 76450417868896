import {Task, TaskMemberType} from "../../generated-types";
import React from "react";
import {Avatar} from "antd";
import UserAvatar from "../User/UserAvatar";


interface TaskMembersProps {
    task: Task
}

const TaskMembers: React.FC<TaskMembersProps> = ({task}) => {


    const responsible = task.members?.filter(m => m.memberType == TaskMemberType.Responsible)
        .map(m => m != null ? <UserAvatar className={"responsibleAvatar"}
            style={{color: '#f56a00', backgroundColor: '#fde3cf'}} key={m.user.id} user={m.user} /> : null) ?? [];

    const members = task.members?.filter(m => m.memberType != TaskMemberType.Responsible)
        .map(m => m != null ? <UserAvatar key={m.user.id} user={m.user} /> : null) ?? [];

    return (<div style={{display: "flex"}}>
        <Avatar.Group
            max={{count: 5, style: {color: '#f56a00', backgroundColor: '#fde3cf'}}}
            size="default"
        >
        {responsible.concat(members)}
        </Avatar.Group>
    </div>)
}

export default TaskMembers