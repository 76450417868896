import {Button, Tag, theme, Tooltip} from "antd";
import {useTaskChangeImportanceMutation} from "../../generated-types";
import {CSSProperties, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {IconArrowDown} from "../Icon/IconArrowDown";
import SelectMenuDropdown, {CustomDropdownItem} from "../AntCastomComponents/SelectMenuDropdown";
import {IconPriorityHigh} from "../Icon/IconPriorityHigh";
import Dropdowned from "../Dropdowned";


export interface TaskImportanceProps {
    // а если не указан идентификатор, тогда надо предавать значение выше и чтобы код выше его сохранял (например, в карточке новой задачи)
    id: string
    value: number,
    onChange?: (value: number) => void;
}

type Priority = {
    title: string,
    bgColor: string,
    hoverColor: string,
    border?: string
    borderHover?: string
    icon?: React.JSX.Element
}

const PriorityName = ({ priority, textStyle }: { priority: Priority, textStyle?: CSSProperties }) => {

    const { t } = useTranslation();

    return <div style={{display: "flex", alignItems: 'center', gap: 4, height: 16}}>
        {priority.icon && <span style={{ fontSize: 16, lineHeight: 1 }}>{priority.icon}</span>}
        <span style={{
            fontSize: 12,
            fontWeight: 300,
            ...textStyle
        }}>{t("task.priority." + priority.title)}</span>
    </div>
}

const TaskImportanceElement = ({ priority }: { priority: Priority }) => {

    const { t } = useTranslation();

    return <Tag style={{
        padding: 8,
        borderRadius: 8,
        backgroundColor: priority.bgColor,
        border: priority.border ? priority.border : '0px',
        margin: 0,
        boxSizing: 'border-box', 
    }}>
        <PriorityName priority={priority} />
    </Tag>
}

function TaskImportance(props: TaskImportanceProps) {
    const { t } = useTranslation();
    const [value, setValue] = useState<number>(props.value)
    const [hover, setHover] = useState(false)
    const { token } = theme.useToken()
    const [opened, setOpened] = useState(false);
    const btnRef=useRef<HTMLButtonElement>(null)

    const priority: Priority[] = [
        {
            title: 'withoutPriority',
            bgColor: 'unset',
            hoverColor: 'unset',
            border: `solid 2px ${token.colors.ui.bgLight}`,
            borderHover: `solid 2px ${token.colors.ui.bgLight}`
        },
        {
            title: 'low',
            bgColor: token.colors.ui.bgLight3,
            hoverColor: token.colors.ui.bgLight
        },
        {
            title: 'medium',
            bgColor: token.colors.ui.priorityMedium,
            hoverColor: token.colors.ui.priorityMediumHover
        }, 
        {
            title: 'critical',
            bgColor: token.colors.ui.priorityHigh,
            hoverColor: token.colors.ui.priorityHighHover,
            icon: <IconPriorityHigh/>
        }
    ]

    const [taskChangeImportance] = useTaskChangeImportanceMutation({
        onCompleted: () => {

        }
    })

    const dropdownItems: CustomDropdownItem[] = priority.map((priority, index) => ({
        label: <TaskImportanceElement priority={priority} />,
        type: "item",
        key: index,
        selected: index === value,
        onClick: () => {
            setOpened(false)
            taskChangeImportance({
                variables: {
                    importance: index,
                    id: props.id
                },
                onCompleted: () => {
                    setValue(index)
                }

            })
            if (props.onChange)
                props.onChange(index)
        }
    } as CustomDropdownItem)).reverse()

    const backgroundColor = hover ? priority[value].hoverColor : priority[value].bgColor

    return (
        <Tooltip title={t("task.importance")}>
                <Button style={{
                    width: 165,
                    backgroundColor: backgroundColor,
                    border: priority[value].border ? priority[value].border : '0px',
                    boxShadow: 'unset',
                    color: token.colors.font.primary,
                    flexDirection: 'row-reverse',
                    boxSizing: 'border-box',
                    borderRadius: 8,
                    padding: 8,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    fontSize: 16,
                    height: 'auto'

                }}
                    styles={{ icon: { display: "flex", alignItems: 'center' } }}
                    icon={<IconArrowDown strokeWidth={'1.5px'} />}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    onClick={()=>setOpened(true)}
                    ref={btnRef}
                >
                    <PriorityName priority={priority[value]}/>
                </Button>
                {opened && <Dropdowned opened={opened}
                title={t('task.priority.prioritySelectTitle')} 
                onClose={()=>setOpened(false)}
                anchor={btnRef}
                >
                            <SelectMenuDropdown items={dropdownItems}/>
                    </Dropdowned>}
        </Tooltip>)
}

export default TaskImportance