import React, {type SVGProps} from "react"

export function IconPlus(props: SVGProps<SVGSVGElement>) {
   
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1"
             strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=plus, size=24">
        <g id="Frame">
            <path id="Vector"
                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_2" d="M8 12H12M12 12H16M12 12V8M12 12V16" stroke="currentColor" strokeLinecap="round"
                  strokeLinejoin="round"/>
        </g>
    </g>
</svg>

    )
}
