import React, {useEffect, useMemo, useState} from 'react';
import {Button, Drawer, Form, Space} from 'antd';
import {useTranslation} from "react-i18next";
import {
    AddTaskInput,
    ExternalFile,
    Project,
    Task,
    TaskFile,
    TaskMember,
    useAddTaskMutation
} from "../../generated-types";
import TaskCard, {valuesToTaskInput} from "./TaskCard";
import {makeVar, ReactiveVar, useReactiveVar} from "@apollo/client";

import {allProjects, updateProjectInCache} from "../../subscriptions/allProjects";
import RefetchesList from "../../queries/RefetchesList";
import ProjectBreadcrumb from "../ProjectBreadcrumb";


interface TaskNewModalProps {
}

export const TaskNewModal: React.FC<TaskNewModalProps> = ({ }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const addTaskCtx = useReactiveVar(addTaskContext);

    const [task, setTask] = useState<Task>({
        title: "", description: "", members: [] as TaskMember[], files: [] as TaskFile[]
    } as Task);

    useEffect(() => {
        const title = addTaskCtx.title ?? "";

        setTask({
            title: title,
            description: "",
            members: [] as TaskMember[],
            files: [] as TaskFile[],
            startDate: addTaskCtx.startDate,
            endDate: addTaskCtx.endDate,
            startTime: addTaskCtx.startTime,
            endTime: addTaskCtx.endTime,
        } as Task)

        form.setFieldValue("title", title)

        // Эта строка открывает окно добавления задачи при изменении реактивной переменной addTaskCtx
        if (addTaskCtx.taskViewId != undefined)
            setOpen(true)
    }, [addTaskCtx.title, addTaskCtx.startTime, addTaskCtx.startDate, addTaskCtx.endDate, addTaskCtx.endTime, addTaskCtx.projectId]);

    const allP = useReactiveVar(allProjects);
    const project = useMemo(() => {
        return allP.projects.find(p => p.id === addTaskCtx.projectId)
    }, [addTaskCtx.projectId])

    const [addTask] = useAddTaskMutation({
        onCompleted: () => {
            setOpen(false);
            form.resetFields();
            addTaskContext({});
        },
        refetchQueries: [RefetchesList.TasksByViewId],
        update: (cache) => {
            //Обновление статистики проекта для отображения актуальных данных в карточке
            let statistics = {...project?.statistics};

            if (statistics.totalTasks!==undefined) {
                statistics.totalTasks++;
            }

            const values = { ...project, statistics}
            updateProjectInCache(cache,values as Project)
        }
    });

    //Обработчик клика по кнопке сохранения новой задачи, при удачном submit выполнится мутация
    const save = () => {
        form.submit();
    };

    if (!project)
        return null

    const onClose = () => {
        addTaskContext({});//сбрасываем контекст, чтобы после закрытия без сохранения можно было создать задчу
        setOpen(false);
    };
    //Функция передаваемая в форму, отрабатывающая при успешном submit
    const saveTask = (values: any, members: TaskMember[], files: ExternalFile[], tags: string[]) => {
        const addData = valuesToTaskInput(values, members, files, tags);

        const input: AddTaskInput = {
            tasksViewId: addTaskCtx.taskViewId,
            tasksViewColumnId: addTaskCtx.tasksViewColumnId,
            task: addData
        }
        input.task.projectId = project.id;
        addTask({ variables: { input } })

    };

    return (
        <div style={{ height: "40px", marginTop: "auto" }}>
            <Drawer key="2"
                title={<>{t('task.createWindowTitle')}<ProjectBreadcrumb projectId={project.id} /></>}
                width={720}
                onClose={onClose}
                open={open}
                    styles={{body: {paddingBottom: 80}}}
                footer={
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <Button type="primary" block onClick={save} htmlType="submit">
                            {t('task.create')}
                        </Button>
                    </Space>
                }
            >
                {open && <TaskCard
                    key={task.id ?? task.title}
                    projectId={project.id}
                    task={task} save={saveTask}
                    project={project}
                    form={form}
                />}
            </Drawer>
        </div>
    );
};


export interface AddTaskContext {
    projectId?: string | undefined,
    taskViewId?: string | undefined,
    tasksViewColumnId?: string | undefined,
    title?: string | undefined
    status?: string | undefined
    parentId?: string | undefined
    startDate?: Date | undefined
    endDate?: Date | undefined
    startTime?: number | undefined
    endTime?: number | undefined
}

function emptyState(): AddTaskContext {
    return {
        projectId: undefined,
        taskViewId: undefined,
        tasksViewColumnId: undefined,
        title: undefined,
        status: undefined,
        startDate: undefined,
        endDate: undefined,
        startTime: undefined,
        endTime: undefined,
    }
}

export const addTaskContext: ReactiveVar<AddTaskContext> = makeVar<AddTaskContext>(emptyState());
