
import React, {type SVGProps} from "react"
import {theme} from "antd";

export function IconDownload(props: SVGProps<SVGSVGElement>) {
   
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1"
             strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=download, size=24">
        <path id="Vector"
              d="M24 23.76H0V22.26H24V23.76ZM12 19.365L4.875 12.24L5.94 11.175L11.295 16.53V0H12.795V16.455L18.075 11.175L19.14 12.24L12.015 19.365H12Z"
              fill="currentColor"/>
    </g>
</svg>

    )
}
