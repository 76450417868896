import React, {useMemo, useState} from 'react'
import {Input, theme,} from 'antd';
import {Project, Tag, User, UsersUnion} from "../../generated-types";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import type {GlobalToken} from "antd/es/theme/interface";
import TagDisplayItem from "./TagDisplayItem";
import {IconTag} from "../Icon/IconTag";
import TagEditor, {tagDefaultColor, TagEditorContext} from "./TagEditor";
import {IconArrowLeft} from "../Icon/IconArrowLeft";
// import TagItem from "./TagItem";

const TaggerDropdownedStyled = styled.div<{ $token: GlobalToken }>`
    & {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    & > .inner-container {
        border-radius: 16px;
        padding: 12px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        background-color: ${({$token}) => $token.colors.ui.bgLight};
    }

    .editor-title {
        display: flex;
        align-items: center;
        color: ${({$token}) => $token.colors.ui.bgDark};

        svg {
            cursor: pointer;
            padding: 2px;
            border-radius: 2px;
        }

        svg:hover {
            background-color: ${({$token}) => $token.colors.ui.bgLight};
        }
    }

    .tags-holder {
        //margin: 12px;
    }

    .create-and-edit {
        display: flex;
        align-items: center;
        gap: 4px;
        color: ${({$token}) => $token.colors.font.accent};

        a {
            font-size: 130%;
        }
    }

    .inner-container-header {
        //padding: 8px 12px 0px;
    }
`

export const Search = styled(Input)<{ $token: GlobalToken }>`
    //margin: 8px 12px;
    width: 90%;
    border-radius: 8px;
    background-color: ${(p) => p.$token.colors.ui.bgLight2};

    color: ${(p) => p.$token.colors.font.primary};
`


const TaggerDropdowned: React.FC<{
    selectedTags: Tag[],
    availableTags: Tag[],
    projectTags?: Tag[],
    unionTags?: Tag[],
    personalTags?: Tag[],
    project?: Project,
    union?: UsersUnion,
    user?: User,
    allowEditUsersUnionTags: boolean,
    allowEditProjectTags: boolean,
    allowEditPersonalTags: boolean,
    onAdd: (tag: Tag) => boolean
    onRemove: (tag: Tag) => boolean
}> = ({
          selectedTags, availableTags, onRemove, onAdd, project, union, user,
          projectTags, unionTags, personalTags,
          allowEditUsersUnionTags, allowEditPersonalTags, allowEditProjectTags
      }) => {
    const {t} = useTranslation()
    const {token} = theme.useToken();
    const [searchValue, setSearchValue] = useState<string | null>(null);
    const [openedEditor, setOpenedEditor] = useState(false);

    const filterFunc = (value: Tag) => {
        if (!searchValue) return true;
        return value.title.toLowerCase().includes(searchValue.toLowerCase());
    };

    let pp = availableTags.filter(v => true); // TODO: фильтрация

    // Список тегов, которые отображаются в далоговом окне редактирование тегов
    let editorTagsList: Tag[] = [];
    let editorContext: TagEditorContext = {}
    // TODO: только тэги проекта, а как быть с персональными тегами и тегами команды?
    // if (allowEditProjectTags) {
    //     editorTagsList = projectTags ?? []
    //     editorContext.projectId = project?.id
    // }

    const filtered = useMemo(() => {
        return pp.filter(p1 => {
            return !selectedTags.some(p2 => p1.id == p2.id)
        }).filter(filterFunc);
    }, [searchValue, selectedTags])


    if (allowEditPersonalTags) {
        editorTagsList = personalTags ?? []
        editorContext.userId = user?.id
    }
        // TODO: return back?
        // else if (allowEditProjectTags && project) {
        //     editorTagsList = projectTags ?? []
        //     editorContext.projectId = project?.id;
    // }
    else if (allowEditUsersUnionTags && union) {
        editorTagsList = unionTags ?? []
        editorContext.usersUnionId = union?.id
    }

    if (openedEditor) {
        return <TaggerDropdownedStyled $token={token}>
            <div className={"editor-title"}>
                <IconArrowLeft onClick={e => {
                    setOpenedEditor(false);
                }}/>
                {t('tags.createAndEdit')}
            </div>
            <TagEditor key={""} tag={{color: tagDefaultColor, id: ""} as Tag} context={editorContext} removable={true}
                       newItem={true}/>
            {editorTagsList.map(t => {
                return <TagEditor key={t.id} tag={t} context={editorContext} removable={true} newItem={false}/>
            })}
        </TaggerDropdownedStyled>
    }

    return (
        <TaggerDropdownedStyled $token={token}>
            {selectedTags.length > 0 && <div className={"inner-container"}>
							<div className={"inner-container-header"}>{t('tags.stillAdded')}</div>
	            <div>
                  {selectedTags.map(m => {
                      return <TagDisplayItem tag={m} key={m.id}
                                             onRemove={() => {
                                                 onRemove(m)
                                             }}/>;
                  })}
	            </div>
            </div>}

            {pp.length > 0 && (filtered.length > 0 && searchValue == null || searchValue != null) &&
			        <div className={"inner-container"}>
							<div className={"inner-container-header"}>{t('tags.addProjectTag')}</div>
							<Search $token={token} placeholder={t('tags.search')}
							        onChange={(e) => {
                          setSearchValue(e.target.value)
                      }}
							/>
							<div className={"tags-holder"}>
                  {filtered.map(m => {
                      return <TagDisplayItem tag={m}
                                             onClick={() => {
                                                 onAdd(m)
                                             }}
                                             removable={false}/>;
                  })}
                  {filtered.length == 0 && <div>{t('tags.notFound')}</div>}
							</div>
						</div>}

            <a className={"create-and-edit"} onClick={(e) => {
                setOpenedEditor(true);
                e.stopPropagation()
                e.preventDefault()
            }}><IconTag/> {t('tags.createAndEdit')}</a>
        </TaggerDropdownedStyled>
    )
}

export default TaggerDropdowned