import {Badge, Divider, List, Tooltip} from 'antd';
import React, {useState} from 'react';
import {Task, TasksView} from "../../generated-types";
import {useTranslation} from 'react-i18next';
import Tagger from "../Tagger/Tagger";
import ProjectBreadcrumb from "../ProjectBreadcrumb";
import TaskChangeColumn from "./TaskChangeColumn";
import dayjs from 'dayjs';
import TaskMembers from "./TaskMembers";
import TaskResolved, {ResolvedCard} from "./TaskResolved";
import TaskTitle from "./TaskTitle";
import TaskResolvedBlur from "./TaskResolvedBlur";

const dateFormat = 'DD.MM';

const TasksListItem: React.FC<{task: Task, taskView: TasksView, showProjectTitle?: boolean}> = (
    {task, taskView, showProjectTitle = true}
) => {
    const {t} = useTranslation();
    const [hovered, setHovered] = useState(false);
    const showDates = task.startDate || task.endDate;

    const datesInfo =
        (task.startDate ? dayjs(task.startDate).format(dateFormat) : "")
        + (task.startDate && task.endDate ? "-" : "") +
        (task.endDate ? dayjs(task.endDate).format(dateFormat) : "");

    const item = <List.Item style={ResolvedCard(task.resolved,{paddingRight: 5, position: "relative", padding: "6px 0"})}
                            onMouseEnter={() => setHovered(true)}
                            onMouseLeave={() => setHovered(false)}>
        {!hovered && <TaskResolvedBlur task={task} />}
        <div className={"taskNumber"} style={{
            position: "absolute",
            top: 3,
            left: 3}}>#{task.number}</div>
        <TaskChangeColumn taskView={taskView} task={task} allowEdit={!task.resolved}/>
        <Divider type={"vertical"}/>
        <List.Item.Meta
            title={<>
                {/*<TaskImportance value={task.importance} id={task.id} key={task.importance}/>*/}
                <TaskResolved
                    id={task.id}
                    disabled={task.project.archived || task.project.paymentAccount?.isBlocked}
                    value={task.resolved}
                    key={""+task.resolved}
                />
                <TaskTitle task={task} />
                {task.tags && <Tagger projectId={task.project.id} defaultValue={task.tags}/>}
            </>}
            description={showProjectTitle && <ProjectBreadcrumb projectId={task.project.id}/>}
        />

        <TaskMembers task={task} />
    </List.Item>;

    if (showDates){
        let today = dayjs().startOf("day")
        let deadlineOverdue = dayjs(task.endDate).diff(today, "day");

        if (deadlineOverdue == 0)
            return <Tooltip title={t('task.currentTask')}><Badge.Ribbon text={datesInfo} color="orange">{item}</Badge.Ribbon></Tooltip>
        else if (deadlineOverdue < 0)
            return <Tooltip title={t('task.deadlineOverdue')}><Badge.Ribbon text={datesInfo} color="red">{item}</Badge.Ribbon></Tooltip>
    }

    return item;
}

const TasksList: React.FC<{tasks: Task[], taskView: TasksView, showProjectTitle?: boolean}> = ({tasks, taskView,showProjectTitle}) => {
    return (
        <div style={{flexGrow: 1}}>
            <List
                itemLayout="horizontal"
                dataSource={tasks}
                renderItem={(task) => (
                    <TasksListItem key={task.id} task={task} taskView={taskView} showProjectTitle={showProjectTitle} />
                )}
            />
        </div>
    )
};

export default TasksList;
