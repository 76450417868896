import React, {useState} from 'react';
import {Select} from 'antd';
import {useTranslation} from 'react-i18next';
import {AccessLevel} from "../generated-types";

interface AccessLevelSelector {
    selected: AccessLevel,
    disabled?: boolean,
    onChange: (Level: AccessLevel) => boolean
}

const AccessLevelSelector: React.FC<AccessLevelSelector> = (props) => {
    const {t} = useTranslation();
    const [val, setVal] = useState<AccessLevel>(props.selected);

    return <Select
        defaultValue={props.selected}
        variant={"outlined"}
        disabled={props.disabled}
        value={val}
        onChange={v=> {
            let res = props.onChange(v);
            if (!res) return;
            setVal(v);
        }}
        options={[
            { value: AccessLevel.Admin, label: t('accessLevel.admin') },
            { value: AccessLevel.Member, label: t('accessLevel.member') }
        ]}
    />
};

export default AccessLevelSelector;