import { ConfigProvider, Dropdown, DropdownProps, Menu, theme } from 'antd'
import { ItemType, MenuItemType } from 'antd/lib/menu/interface'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

//Кастомный компонент выпадашки для селектов приоритета и доски, вроде еще где-то используется
export interface CustomDropdownItem extends MenuItemType {
    selected: boolean,
}

const DropdownItem = styled.div<{ selected: boolean, selectedColor: string }>`
    padding: 4px 16px;
    width: 100%;
    position: relative;
    box-sizing: border-box;

    &::before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 2px;
        background-color: ${({ selected, selectedColor }) => selected ? selectedColor : 'unset'};
        
    }
    
`

const CustomDropdownElementContainer = ({ children, selected }: PropsWithChildren<{ selected: boolean }>) => {

    const { token } = theme.useToken()

    return <DropdownItem selectedColor={token.colors.ui.accent} selected={selected}>
        {children}
    </DropdownItem>
}


function SelectMenuDropdown({ children, items }: PropsWithChildren<{ items: CustomDropdownItem[] }>) {

    const {token}=theme.useToken()

    const customItems = items.map(item => ({
        ...item,
        label: item?.type === 'item' && item.label && <CustomDropdownElementContainer selected={item.selected}>{item.label}</CustomDropdownElementContainer>
    } as ItemType))


    return (
        <ConfigProvider theme={{
            components: {
                Menu: {
                    controlItemBgHover: token.colors.ui.bgLight,
                    borderRadiusLG: 0,
                    borderRadiusSM: 0,
                    controlItemBgActive: 'unset',
                    controlItemBgActiveHover: token.colors.ui.bgLight,
                    controlPaddingHorizontal: 0,
                    itemMarginInline: 0,
                    itemPaddingInline: 0,
                    itemMarginBlock: 0,
                    itemHeight: 'auto',
                    
                }
            }
        }}>
            <Menu items={customItems} style={{padding: '16px 0px', 
                backgroundColor: token.colors.ui.white, 
                borderRadius: 16, 
                width: 'auto',
                border: 'unset'
                }}/>
        </ConfigProvider >
    )
}

export default SelectMenuDropdown