import React, { useRef, useState } from 'react';
import { Button, GlobalToken, Space, theme, Tooltip } from "antd";
import { Task, TasksView, TasksViewColumn, useTaskChangeColumnInViewMutation, useTasksViewsQuery } from "../../generated-types";
import { useTranslation } from "react-i18next";
import { IconArrowDown } from '../Icon/IconArrowDown';
import SelectMenuDropdown, { CustomDropdownItem } from '../AntCastomComponents/SelectMenuDropdown';
import Dropdowned from '../Dropdowned';

const getStyleByStatus = (s: TasksViewColumn, token: GlobalToken) => {
    switch (s.color) {
        case "blue":
            return { color: token.colors.ui.project3 }
        case "orange":
            return { color: token.colors.ui.accent }
        case "green":
            return { color: token.colors.ui.project4 }
        case "red":
            return {}
        default:
            return {}
    }
}



interface TaskChangeColumnProps {
    taskView: TasksView;
    task: Task
    allowEdit?: boolean
}

const TaskChangeColumnSelectRow = ({ column }: { column: TasksViewColumn }) => {

    const { t } = useTranslation()
    const { token } = theme.useToken()
    return <div style={{ width: '100%', height: 16, display: 'flex', justifyContent: 'start', alignItems: 'center', gap: 8 }}>
        <span style={getStyleByStatus(column, token)}>•</span>
        <span style={{ fontSize: 12, lineHeight: 1, textAlign: 'start' }}>{t('task.columns.' + column.title)}</span>
    </div>
}

const TaskChangeColumn = ({ taskView, task, allowEdit = true }: TaskChangeColumnProps) => {

    const [hover, setHover] = useState(false)
    const btnRef = useRef<HTMLButtonElement>(null)
    const [opened, setOpened] = useState(false)
    const { t } = useTranslation();
    const { token } = theme.useToken();

    const [changeColumn] = useTaskChangeColumnInViewMutation({
        // refetchQueries: [RefetchesList.TasksByViewId]
    })

    const backgroundColor = hover ? token.colors.ui.bgLight : token.colors.ui.bgLight3

    const dropdownItems = taskView.columns?.filter((c: TasksViewColumn) => c != undefined).map((clmn: TasksViewColumn) => ({
        label: <TaskChangeColumnSelectRow column={clmn} />,
        type: 'item',
        selected: clmn?.title === task.status,
        key: clmn.id,
        onClick: () => {
            setOpened(false)
            if (clmn)
                changeColumn({
                    variables: {
                        taskId: task.id,
                        taskViewId: taskView.id,
                        columnId: clmn.id
                    }
                })
        }
    }) as CustomDropdownItem)

    
    return <>
        <Button style={{
            width: 165,
            backgroundColor: backgroundColor,
            border: '0px',
            boxShadow: 'unset',
            color: token.colors.font.primary,
            flexDirection: 'row-reverse',
            boxSizing: 'border-box',
            borderRadius: 8,
            padding: 8,
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 16,
            height: 'auto'

        }}
            styles={{ icon: { display: "flex", alignItems: 'center' } }}
            icon={<IconArrowDown strokeWidth={'1.5px'} />}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => setOpened(true)}
            ref={btnRef}
        >
            {dropdownItems.find((value)=>value.selected)?.label}
        </Button>
        {opened && <Dropdowned opened={opened}
            title={t('task.priority.prioritySelectTitle')}
            onClose={() => setOpened(false)}
            //styles={{dropdown: {width: 'auto'}}}
            anchor={btnRef}
        >
            <SelectMenuDropdown items={dropdownItems} />
        </Dropdowned>}
    </>
}

export default TaskChangeColumn;
