import styled, {css} from "styled-components";
import type {GlobalToken} from "antd/es/theme/interface";

export const DropdownedStyled = styled.div<{
    $position: { x: any, y: any },
    $size: { width: any, height: any },
    $mobile?: string,
    $token: GlobalToken
    $backdrop?: boolean,
    $justified: boolean
}>`
    ${({$mobile}) => $mobile !== "true" && css`
        //display: inline-block;
    `};
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    ${({$backdrop}) => $backdrop && 'background-color: rgba(0, 0, 0, 0.5);'}
    z-index: 1020;
    // TODO: я не пойму, где посмотреть параметры тени в фигме. на глаз сделал 

    transition: all 0.3s ease-in-out;
  
    .content-container {
        box-shadow: 0 3px 19px 0 #00000038;
        position: absolute;
        background-color: ${({$token}) => $token.colors.ui.white};
        border-radius: 16px;
        height: ${({$size}) => $size.height};
        left: ${({$position}) => $position.x + "px"};
        top: ${({$position}) => $position.y + "px"};
        overflow: hidden;
        transform: ${({$justified}) => $justified ? 'translate(-50%,-50%)' : 'none'};
        transition: all .1s ease-in-out;
    }
`
