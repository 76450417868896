import React, {useRef, useState} from 'react';
import {Card, Empty, List, Tabs} from 'antd';
import ConnectYandexDisk from "./ConnectYandexDisk";
import {ExternalFile, Project, useAttachFolderMutation, useFilesInProjectQuery} from "../../generated-types";
import {useTranslation} from 'react-i18next';
import RefetchList from "../../queries/RefetchesList";
import AttachFilesButton, {FilesList} from "./AttachFilesButton";
import {editTaskContext} from "../Tasks/TaskEditor";
import DownloadFileButton from "./DownloadFileButton";
import {ExternalFileWithId} from "../Tasks/TaskCard";
import {CenterJustify} from "../Dropdowned";

interface ProjectFilesListProps {
    project: Project,
    isAdmin: boolean
}

const ProjectFilesList: React.FC<ProjectFilesListProps> = ({project, isAdmin}) => {
    const {t} = useTranslation();
    const [inProgress, setInProgress] = useState(false);
    const attachFilesRef = useRef<HTMLDivElement>(null)

    const [attachFolder] = useAttachFolderMutation({
        onCompleted: () => {
            setInProgress(false)
            // m.destroy();
        },
        refetchQueries: [RefetchList.Project]
    })

    const filesInTasks = useFilesInProjectQuery({
        variables: {projectId: project.id as string}
    })
    const filesInProject = filesInTasks.data?.filesInProject;

    const onFolderChanged = (items: ExternalFile[])=>{
        setInProgress(true)
        // TODO: не очень-то получилось
        attachFolder({
            variables: {
                path: items[0].path,
                projectId: project.id,
            }
        })
    };

    const attachBtn = <AttachFilesButton
        anchor={attachFilesRef} centerJustify={CenterJustify.start}
        basePath={"/"} loading={inProgress} onFinish={onFolderChanged} selectable={true} onlyFolders={true}
        buttonTitle={t('file.projectAttachFolderBtn') as string} title={t('file.projectAttachFolder') as string}
        selectedItems={project.attachedFolders.map((name, path) => ({name, path} as unknown as ExternalFile))}
    />;

    if (project.attachedFolders.length == 0 && isAdmin) {
        return <div ref={attachFilesRef}>
            <ConnectYandexDisk allowAttachToken={isAdmin}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('file.adminHasToAttachFolder')}>
                    {isAdmin && attachBtn}
                </Empty>
            </ConnectYandexDisk>
        </div>
    }

    const tabs = project.attachedFolders.map((f, i) => {
        const id = String(i + 1);
        return {
            label: f.path,
            key: id,
            children: <FilesList folders={[f]} selectable={false}/>,
            closable: true,
        };
    });
    tabs.unshift({
        label: t("file.filesInTasks"),
        key: "",
        closable: false,
        children:
            <List
                grid={{ gutter: 16, column: 3 }}
                dataSource={filesInProject}
                renderItem={(item) => (
                    <List.Item>
                        <Card
                            title={<>{item.path}<DownloadFileButton file={item as unknown as ExternalFileWithId} /></>}
                        >{
                            item.tasks.map(t=> {
                                return <List.Item.Meta
                                    description={<a onClick={(e)=>{
                                        editTaskContext({taskId: t.id});
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }} >{t.title}</a>}
                                    // description={<Breadcrumb items={description} />}
                                />
                            })
                        }</Card>
                    </List.Item>
                )}
            />
    })

    return (
        <ConnectYandexDisk allowAttachToken={isAdmin}>
            <Tabs tabBarExtraContent={isAdmin && attachBtn} items={tabs} style={{marginBottom: 0}}/>
        </ConnectYandexDisk>
    )
};

export default ProjectFilesList;