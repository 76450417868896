import React from 'react';
import {Button, Divider, List, message, Popconfirm} from 'antd';
import {
    AccessLevel,
    PaymentSlot,
    PaymentSlotType,
    Project,
    useProjectAddMembersMutation,
    useProjectMemberChangeLevelMutation,
    useProjectRemoveMemberMutation,
    useUserPaymentAccountQuery
} from "../../generated-types";
import UserSearch, {IInvite} from "../User/UserSearch";
import {useTranslation} from "react-i18next";
import AccessLevelSelector from "../AccessLevelSelector";
import {showErrors, showLoading} from "../helpers";
import ProjectMembersInvites from "./ProjectMembersInvites";
import {DeleteOutlined, TeamOutlined, UsergroupAddOutlined} from "@ant-design/icons";
import {authState} from "../../routes/Auth/authContext";
import {useReactiveVar} from "@apollo/client";
import UserListItem from "../User/UserListItem";
import {slotsTypesWithAmounts} from "../Payment/SlotsTypesWithAmounts";
import {addSlotsContext} from "../Payment/AddSlotsModal";
import {CountInvitesMembers} from "../CountInvitesMembers";
import {updateProjectInCache} from "../../subscriptions/allProjects";

interface ProjectMembersEditorProps {
    project: Project
}

const ProjectMembersEditor: React.FC<ProjectMembersEditorProps> = ({project}) => {
    const {t} = useTranslation()
    const authInfo = useReactiveVar(authState);
    const [messageApi, contextHolder] = message.useMessage();

    const [Add, opt1] = useProjectAddMembersMutation({

        update: (cache, r) => {
            updateProjectInCache(cache, r.data?.changedProject as Project)
        }
    });

    const [ChangeLevel, opt2] = useProjectMemberChangeLevelMutation({});

    const [Remove, opt3] = useProjectRemoveMemberMutation({

        update: (cache, r) => {
            updateProjectInCache(cache, r.data?.changedProject as Project)
        }
    });
    const {data: {paymentAccount} = {payment: null}, loading} = useUserPaymentAccountQuery({
        variables: {id: project.paymentAccount.id}
    });

    const isAdmin = project.members.some(m => m.user.id === authInfo.user.id && m.accessLevel === AccessLevel.Admin)
    const slots = paymentAccount?.slots as PaymentSlot[];

    let type = '';

    if (project.userAsOwner) {
        type = 'personalProjectAdditionalMember'
    }
    if (project.usersUnionAsOwner) {
        type = 'usersUnionProjectMember'
    }

    const allSlots = Object.keys(PaymentSlotType)
        .map(k => slotsTypesWithAmounts(slots as any ?? [], k))
    const emptySlotsCount = () => {
        const membersSlots = allSlots.find((s) => s.type === type);
        return membersSlots?.payEmptySlots.length! + membersSlots?.freeEmptySlots.length!
    }
    const empty = emptySlotsCount();

    const openAddSlotsModal = () => {
        addSlotsContext({
            slotsType: allSlots
        });
    }


    return (
        <div style={{margin: 10}}>
            {contextHolder}
            {showErrors([opt1, opt2, opt3])}
            {showLoading([opt1, opt2, opt3, {loading}])}
            {isAdmin && <><UserSearch
                                      maxMembers={empty}
                                      project={project}
                                      disabled={project.paymentAccount?.isBlocked || project.archived}
                                      addUsersUnions={true}
                //TODO: вставить проверку на наличие свободных слотов только для админа
                                      membersAdded={({users, unions, emails}, uMembers) => {
                                          if (users.length + emails.length + uMembers <= empty) {
                                              Add({
                                                  variables:{
                                                      input: {
                                                          maxMembers: uMembers,
                                                          emails: emails,
                                                          users: users.map(u => u.id),
                                                          unions: unions.map(u => u.id),
                                                          projectId: project.id
                                                      }
                                                  }
                                              })
                                              return true;
                                          } else {
                                              openAddSlotsModal();
                                              return false
                                          }
                                      }} members={project.members.map(v => v.user)}
                                      invites={project.invites.map(i => (
                                          {email: i.email, user: i.user, usersUnion: i.usersUnion} as IInvite)
                                      )}/>
                {isAdmin && slots && type.length &&
                    <CountInvitesMembers slots={slots} type={type[0].toUpperCase() + type.slice(1)}/>}
                <Divider key={1}><TeamOutlined rev={undefined}/> {t('project.members')}</Divider></>
            }
            {project.members != null ? <List
                    loading={false}
                    itemLayout="horizontal"
                    loadMore={false}
                    dataSource={project.members}
                    renderItem={(item) => {
                        return (item &&
                            <List.Item
                                actions={[

                                    isAdmin && <Popconfirm
                                        disabled={item.user.id == authInfo.user.id || project.archived}
                                        icon={null}
                                        title={t('really')}
                                        description={t('projectMembers.SureRemove')}
                                        onConfirm={() => {
                                            if (item.user.id === authInfo.user.id) {
                                                messageApi.warning(t('youCantEditYourselfInProjectMembers'));
                                                return false;
                                            } else
                                                Remove({
                                                    variables: {
                                                        input: {
                                                            userId: item.user.id,
                                                            projectId: project.id
                                                        }
                                                    }
                                                })
                                        }
                                        }
                                        onCancel={() => {
                                        }}
                                        okText={t('remove')}
                                        cancelText={t('no')}
                                    >
                                        <Button type="text" danger disabled={item.user.id == authInfo.user.id}>
                                            <DeleteOutlined rev={undefined}/>
                                        </Button>
                                    </Popconfirm>
                                ]}
                            >
                                <UserListItem user={item.user}/>
                                {item.asUnion && <div style={{margin: "0px 20px", color: "gray"}}><span
                                    style={{color: "#d7d7d7"}}>{t('usersUnion.team')}</span> {item.asUnion.title}</div>}
                                <AccessLevelSelector selected={item.accessLevel}
                                                     disabled={!isAdmin || project.archived}
                                                     onChange={(level: AccessLevel) => {
                                                         if (item.user.id === authInfo.user.id) {
                                                             messageApi.warning(t('youCantEditYourselfInProjectMembers'));
                                                             return false;
                                                         } else
                                                             ChangeLevel({
                                                                 variables: {
                                                                     input: {
                                                                         userId: item.user.id,
                                                                         projectId: project.id,
                                                                         level
                                                                     }
                                                                 }
                                                             })
                                                         return true;
                                                     }
                                                     }/>
                            </List.Item>
                        )
                    }}
                />
                : <div>{t('project.noMembers')}</div>
            }
            {project.invites.length > 0 && [
                <Divider key={1}><UsergroupAddOutlined rev={undefined}/> {t('project.invitesList')}</Divider>,
                <ProjectMembersInvites key={2} project={project} invites={project.invites} allowRemove={isAdmin}/>
            ]}
        </div>
    );
};

export default ProjectMembersEditor;