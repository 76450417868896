import React, {type SVGProps} from "react"

export function IconHome(props: SVGProps<SVGSVGElement>) {
   
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1"
             strokeOpacity="1" {...props} stroke="currentColor">
            <g id="name=home, size=24" clipPath="url(#clip0_29_5457)">
        <g id="Frame 3">
            <path id="Vector" d="M12 17V13" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_2"
                  d="M2 7.99998L11.7317 3.13414C11.9006 3.04969 12.0994 3.04968 12.2683 3.13414L22 7.99998"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            <path id="Vector_3" d="M20 11V19C20 20.1046 19.1046 21 18 21H6C4.89543 21 4 20.1046 4 19V11"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
    </g>
    <defs>
        <clipPath id="clip0_29_5457">
            <rect width="24" height="24" fill="white"/>
        </clipPath>
    </defs>
</svg>

    )
}
