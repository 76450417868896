import { theme } from 'antd'
import React, { PropsWithChildren } from 'react'
import { ThemeProvider } from 'styled-components'

function StyledThemeProvider({children}:PropsWithChildren) {

    const { token } = theme.useToken()

    return (
        <ThemeProvider theme={token}>
            {children}
        </ThemeProvider>
    )
}

export default StyledThemeProvider