import React, { useState } from 'react';
import {
    CheckOutlined, CloudDownloadOutlined,
    FolderOpenOutlined,
    HomeOutlined,
    LinkOutlined
} from '@ant-design/icons';
import {
    Breadcrumb, Button,
    ButtonProps, List, Modal, Select, Switch, theme, Tooltip, Typography
} from 'antd';
import {
    AttachedFolder,
    ExternalFile, ExternalFileType, useGetDownloadLinkLazyQuery,
    useYandexDiskGetFolderItemsQuery
} from "../../generated-types";
import { useTranslation } from 'react-i18next';
import Spinner from "../Spinner";
import {ExternalFileWithId} from "../Tasks/TaskCard";
import { IconDownload } from '../Icon/IconDownload';

interface DownloadFileButtonProps {
    file: ExternalFileWithId
}


const DownloadFileButton: React.FC<DownloadFileButtonProps> = ({file}) => {
    const {t} = useTranslation()
    const {token}=theme.useToken()
    const [hover, setHover]=useState(false)

    const [getDownloadLink, { data: linkData }] = useGetDownloadLinkLazyQuery({})
    if (linkData?.link) {
        const w = window.open(linkData?.link)
        console.log(w)
    }

    return (<Tooltip title={t("task.downloadFile")}>
        <Button onClick={() => {getDownloadLink({variables: {
                taskFileId: file.id
            }}); }}
            style={{
                color: hover? token.colors.ui.accentHover : token.colors.font.accent,
                border: 'unset',
                backgroundColor: 'unset',
                boxShadow: 'unset',
                padding: 0,
                justifyContent: 'start',
                width: 24
            }}
            styles={{icon: {
                display: 'flex', alignItems: 'center'
            }}}
            icon={<IconDownload fontSize={16}/>}
            onMouseEnter={()=>setHover(true)}
            onMouseLeave={()=>setHover(false)}/>
    </Tooltip>)
}

export default DownloadFileButton