import React, {useEffect, useState} from 'react';
import {Button, Drawer, Form, Space} from 'antd';
import AddButton from "../AddButton";
import {useTranslation} from "react-i18next";
import {
    PaymentSlotType,
    UsersUnion,
    UsersUnionCreateInput,
    useUserPaymentAccountQuery,
    useUsersUnionCreateMutation
} from "../../generated-types";
import {useNavigate} from "react-router-dom";
import UsersUnionSettings from "./UsersUnionSettings";
import {slotsTypesWithAmounts, TypesWithAmounts} from "../Payment/SlotsTypesWithAmounts";
import {PaymentErrorModal} from "../Payment/PaymentErrorModal";
import {addSlotsContext} from "../Payment/AddSlotsModal";
import {tariffTableModalContext} from "../Payment/ModalTariffsTable";
import {updateUsersUnionInCache} from "../../subscriptions/allUsersUnions";


const UsersUnionNew: React.FC = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [slotsType, setSlotsType] = useState<TypesWithAmounts[] | undefined>();
    const [form] = Form.useForm();
    const [union] = useState<UsersUnionCreateInput>({title: "", name: ""});
    const {data: {paymentAccount} = {payment: null}} = useUserPaymentAccountQuery();
    const isBlocked = paymentAccount?.isBlocked;
    const usersUnionAdditionalCost = paymentAccount?.tariff.usersUnionAdditionalCost;
    const allSlots = paymentAccount?.slots;

    const [usersUnionCreate, {  loading }] = useUsersUnionCreateMutation({
        onCompleted: (data) => {
            console.log('onCompleted')
            const {usersUnionCreate: {id}} = data;
            navigate(`/usersUnion/${id}`)
            setOpen(false);
        },
        update: (cache, r) => {
            updateUsersUnionInCache(cache, r.data?.usersUnionCreate as UsersUnion)
        }
    });

    const onCreateUnion = () => {
        console.log('onCreateUnion')
        const slots = slotsType?.find((item) => (
            item.type === 'usersUnion'
        ))
        if (slots?.freeEmptySlots.length !== 0 || slots?.payEmptySlots.length !== 0) {
            showDrawer();
        } else {
            openModals();
        }
    }

    const openModals = () => {
        if (usersUnionAdditionalCost === -1) {
            toggleErrorModal();
        } else {
            addSlotsContext({
                slotsType: slotsType
            })
        }
    }

    const toggleErrorModal = () => {
        setOpenErrorModal((prev) => !prev);
    }

    const openTariffsModal = () => {
        toggleErrorModal();
        tariffTableModalContext({
            paymentAccountId: paymentAccount?.id,
            currentTariffId: paymentAccount?.tariff.id
        })
    }

    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };
    const save = (input: any) => {
        console.log(input)
        usersUnionCreate({variables: {input}})
    };

    useEffect(() => {
        if (allSlots) {
            const items = Object.keys(PaymentSlotType)
                .map(k => slotsTypesWithAmounts(allSlots, k))
            setSlotsType(items)
        }
    }, [allSlots]);

    return (
        <div style={{height: "40px"}}>
            <AddButton disabled={isBlocked}
                       title={t('usersUnion.create')} onClick={onCreateUnion} key="1"/>
            <Drawer key="2"
                    title={t('usersUnion.createWindowTitle')}
                    width={720}
                    onClose={onClose}
                    open={open}
                    styles={{body: {paddingBottom: 80}}}
                    footer={
                        <Space direction="vertical" style={{width: '100%'}}>
                            <Button
                                type="primary"
                                block
                                loading={loading}
                                onClick={form.submit}
                                htmlType="submit">
                                {t('usersUnion.create')}
                            </Button>
                        </Space>
                    }
            >
                <UsersUnionSettings union={union as UsersUnion} save={save} form={form}/>
            </Drawer>
            {openErrorModal &&
                <PaymentErrorModal
                    open={openErrorModal}
                    onCancel={toggleErrorModal}
                    errorText={t('errorModal.errorTariffUnions')}
                    onClick={openTariffsModal}
                />
            }

        </div>
    );
};

export default UsersUnionNew;