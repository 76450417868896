import React, {type SVGProps} from "react"

// TODO: почему не было этой иконки?
export function IconWorker(props: SVGProps<SVGSVGElement>) {

    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
             fillOpacity="1" strokeOpacity="1" {...props} stroke="currentColor">
            <g id="name=main worker, size=16" clipPath="url(#clip0_220_7400)">
                <path id="Vector"
                      d="M3.06702 7.96445C3.87037 5.76 4.89129 2.92444 5.6528 0.844445C5.8369 0.328889 6.28878 0 6.80761 0C7.76158 0 9.32644 0 10.4729 0C11.1758 0 11.6528 0.746667 11.4017 1.44C10.8746 2.89778 10.2469 4.64889 9.85363 5.75111C9.76158 6.00889 9.95405 6.27556 10.2218 6.27556H12.5984C12.9415 6.27556 13.1172 6.68444 12.8913 6.93333L4.64861 15.8756C4.36409 16.1867 3.85363 15.8756 3.99589 15.4756L5.95405 9.94667C6.0461 9.68889 5.85363 9.42222 5.58585 9.42222C5.58585 9.42222 4.7825 9.42222 4.00426 9.42222C3.30133 9.42222 2.81598 8.67556 3.06702 7.97333V7.96445Z"
                />
            </g>
            <defs>
                <clipPath id="clip0_220_7400">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
}
