import {Maybe, Project} from "../../generated-types";
import {WalletOutlined} from "@ant-design/icons";
import React from "react";

interface UserAvatarProps {
    avatarUrl?: string | Maybe<string>,
    p?: Project | null,
    style?: any,
    className?: string,
}

const ProjectAvatar = (props: UserAvatarProps) => {
    const {p, avatarUrl, ...otherProps} = props

    // TODO: иконки сохранять и цвет указывать в настройках проекта (p.icon, p.iconColor)
    return <span style={{padding: "0px 4px"}}>
        <WalletOutlined
            style={{backgroundColor: 'red', padding: 4, borderRadius: 3, width: 24, height: 24, alignSelf: "center"}}
            rev={undefined}/>
    </span>;

    // return <WalletOutlined style={{backgroundColor: 'red', padding: 3, borderRadius: 3}}
    //                        rev={undefined}/>;
    //
    // if (uu)
    //     return (<Tooltip key={uu.id} title={uu.title} placement="top" className={props.className}>
    //         <Avatar src={uu.avatarUrl} icon={<TeamOutlined/>} className={props.className} style={props.style}/>
    //     </Tooltip>)


    // return (<Avatar {...otherProps} src={avatarUrl} icon={<TeamOutlined/>} className={props.className}/>)
}

export default ProjectAvatar