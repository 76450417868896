import React, {useEffect, useMemo, useRef, useState} from 'react';
import {theme} from 'antd';
import {useTranslation} from 'react-i18next';
import {AccessLevel, ProjectMember, Tag, UsersUnionMember} from "../../generated-types";
import {useReactiveVar} from "@apollo/client";
import {allProjects} from "../../subscriptions/allProjects";
import {allUsersUnions} from "../../subscriptions/allUsersUnions";
import {authState} from "../../routes/Auth/authContext";
import {allPersonalTags} from "../../subscriptions/allPersonalTags";
import Dropdowned from "../Dropdowned";
import TooltipButton from "../TooltipButton";
import {Icon} from "../Icon/Icon";
import {IconPlus} from "../Icon/IconPlus";
import TagsDisplay from "./TagsDisplay";
import TaggerDropdowned from "./TaggerDropdowned";

interface TaggerProps {
    onChanged?: (tags: Tag[]) => void
    tags?: Tag[]
    defaultValue?: string[] | Tag[]
    usersUnionId?: string
    projectId?: string
    placeholder?: string
    block?: boolean
    allowAdd?: boolean
    addButtonText?: string
    allowRemove?: boolean
    allowEditProjectTags?: boolean
    allowEditUsersUnionTags?: boolean
    onCloseTag?: (tags: string[]) => void,
    maxTags?: number,
    maxLines?: number,
}

const Tagger: React.FC<TaggerProps> = ({ projectId, allowEditUsersUnionTags = false, usersUnionId,
                                           placeholder,
                                           defaultValue,
                                           onChanged,
                                           tags,
                                           allowAdd = true,
                                           addButtonText,
                                           allowRemove = true,
                                           block,
                                           allowEditProjectTags,
                                           onCloseTag,
                                           maxTags = 100,
                                           maxLines = 100
                                       }) => {
    const {token} = theme.useToken();
    const authInfo = useReactiveVar(authState);
    const [selected, setSelected] = useState<Tag[]>([]);
    const { t } = useTranslation();
    const personalTags = useReactiveVar(allPersonalTags);
    const [opened, setOpened] = useState(false);
    const openedRef = useRef<HTMLDivElement>(null)

    const allP = useReactiveVar(allProjects);
    const allU = useReactiveVar(allUsersUnions);
    const project = allP.projects.find(p => p.id == projectId)
    if (!usersUnionId)
        usersUnionId = project?.usersUnionAsOwner?.id;
    const union = allU.unions.find(p => p.id == usersUnionId)

    const isUnionAdmin = union?.members.some((m: UsersUnionMember) => m.user.id == authInfo.user.id && m.accessLevel == AccessLevel.Admin) ?? false;
    const isProjectAdmin = project?.members.some((m: ProjectMember) => m.user.id === authInfo.user.id && m.accessLevel === AccessLevel.Admin) ?? false
    const allowEditPersonalTags = project?.userAsOwner?.id == authInfo.user.id ?? false;

    // Если проекты персональные, тогда только владелец может исправлять метки этого проекта.
    allowEditUsersUnionTags = allowEditUsersUnionTags && isUnionAdmin && !allowEditPersonalTags;
    allowEditProjectTags = (allowEditProjectTags && isProjectAdmin) ?? false;

    let availableTags = useMemo(() => {
        let res: Tag[] = []
        if (project)
            res = res.concat(project.tags.map(t => ({...t, project: project} as unknown as Tag)));

        if (union)
            res = res.concat(union.tags.map(t => ({...t, UsersUnion: union} as unknown as Tag)));

        if (allowEditPersonalTags)
            res = res.concat(personalTags.tags.map(t => ({...t, user: authInfo.user} as unknown as Tag)));

        if (res.length == 0 && tags)
            res = tags;
        return res;
    }, [project, union, personalTags, authInfo])

    useEffect(() => {
        if (defaultValue) {
            const s = availableTags.filter((tag: Tag) => {
                if (typeof defaultValue[0] === "string")
                    return (defaultValue as string[]).indexOf(tag.title.toLowerCase()) > -1 || (defaultValue as string[]).indexOf(tag.id.toLowerCase()) > -1
                else
                    return (defaultValue as Tag[]).some(v => v.id === tag.id);
            })
            setSelected(s)
        }
    }, [defaultValue, availableTags]);

    //Обработчик изменения выбора тегов в выпадающем меню
    const onChangeHandler = (values: Tag[]) => {
        setSelected(values)
        if (onChanged)
            onChanged(values)
    }

    const addButton = allowAdd ?
        <TooltipButton className={"add-tags-button"}
                       icon={<Icon icon={<IconPlus color={token.colors.ui.accent}/>}/>}
                       type={"text"}
                       onClick={() => {
                           setOpened(true);
                       }}
                       tooltipProps={{title: t('tags.taggerPlaceholder')}}>
            {addButtonText}
        </TooltipButton> : undefined;

    return (
        <div ref={openedRef}>
            <TagsDisplay maxTags={maxTags} maxLines={maxLines} allowRemove={allowRemove}
                         addButton={addButton}
                         onRemove={tag => {
                             onChangeHandler(selected.filter(s => s.id !== tag.id))
                         }}
                         tags={selected}
            />

            {opened && <Dropdowned opened={opened} anchor={openedRef}
				                           onClose={o => {
                                       setOpened(o)
                                   }} title={t('tags.editTaskTags')}>

					    <TaggerDropdowned
						    allowEditPersonalTags={allowEditPersonalTags}
						    allowEditUsersUnionTags={allowEditUsersUnionTags}
						    allowEditProjectTags={allowEditProjectTags}
						    project={project}
						    union={union}
						    user={authInfo.user}
						    personalTags={personalTags.tags}
						    projectTags={project?.tags}
						    unionTags={union?.tags}
						    onAdd={(m: Tag) => {
                                onChangeHandler([...selected, m])
                                return true;
                            }}
												    onRemove={(tag: Tag) => {
                                onChangeHandler(selected.filter(s => s.id !== tag.id))
                                if (onCloseTag) onCloseTag([tag.title])
                                return true;
                            }}
						    selectedTags={selected}
						    availableTags={availableTags}
					    />
				    </Dropdowned>}
        </div>
    );
};

export default Tagger