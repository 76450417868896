import React, {type SVGProps} from "react"

export function IconArrowDown(props: SVGProps<SVGSVGElement>) {
   
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1"
             strokeOpacity="1" {...props} stroke="currentColor">
            <g id="name=arrow-down, size=24" clipPath="url(#clip0_426_36506)">
        <g id="Frame 3">
            <path id="Vector" d="M4 8L12 16L20 8" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
    </g>
    <defs>
        <clipPath id="clip0_426_36506">
            <rect width="24" height="24" fill="white"/>
        </clipPath>
    </defs>
</svg>

    )
}
