import React, {CSSProperties, useRef, useState} from 'react';
import {Breadcrumb, Button, ButtonProps, Checkbox, ConfigProvider, List, theme, Typography} from 'antd';
import {AttachedFolder, ExternalFile, ExternalFileType, useYandexDiskGetFolderItemsQuery} from "../../generated-types";
import {useTranslation} from 'react-i18next';
import Spinner from "../Spinner";
import {IconFiles} from '../Icon/IconFiles';
import Dropdowned, {CenterJustify} from '../Dropdowned';
import {isMobile} from 'react-device-detect';
import {IconClose} from '../Icon/IconClose';
import {IconHome} from '../Icon/IconHome';
import {CheckboxChangeEvent} from 'antd/lib/checkbox';
import {IconFolder} from '../Icon/IconFolder';
import styled from 'styled-components';
import {IconArrowRight} from '../Icon/IconArrowRight';

interface SelectFilesProps {
    title?: string
    /***
     * Базовых путей может быть несколько (например, из проекта и из тимы.
     */
    basePath?: string
    folders?: AttachedFolder[]
    buttonTitle?: string
    loading?: boolean
    onChange?: (items: ExternalFile[]) => void
    onFinish?: (items: ExternalFile[]) => void
    selectedItems?: ExternalFile[]
    onlyFolders?: boolean
    /***
     Показывать или чекбоксы выбора файлов
     ***/
    selectable?: boolean
    multipleSelect?: boolean
    btnProps?: ButtonProps
}

interface FilesListRowProps {
    item: ExternalFile,
    selected: boolean,
    onSelectionChange: (event: CheckboxChangeEvent) => void
    onClick: () => void,
    selectable?: boolean
}

const FilesListRow = ({ item, selected, onSelectionChange, onClick, selectable }: FilesListRowProps) => {

    const [hover, setHover] = useState(false)
    const rowRef = useRef<HTMLDivElement>(null)
    const { token } = theme.useToken()

    return (
        <List.Item style={{
            cursor: "pointer",
            padding: isMobile ? '8px 16px' : '8px 24px',
            justifyContent: 'start', gap: 8,
            boxShadow: 'none',
            backgroundColor: hover ? token.colors.ui.bgLight : 'unset',
            flexWrap: 'nowrap'
        }}
            ref={rowRef}
            onClick={(event) => {

                if (event.target !== rowRef.current) { return }
                onClick()
            }
            }
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            {selectable && <Checkbox
                checked={selected}
                onChange={(event) => {
                    event.stopPropagation()
                    event.preventDefault()
                    onSelectionChange(event)
                }}
            />}
            {item.type == ExternalFileType.Dir && <IconFolder pointerEvents={'none'} opacity={0.5} fontSize={16} strokeWidth={1.5} />}
            <span style={{flexGrow: 1, pointerEvents: 'none'}}>
                <Typography.Text>{item.name}</Typography.Text>
            </span>
            {item.type == ExternalFileType.Dir && <IconArrowRight pointerEvents={'none'} fontSize={16} strokeWidth={2} />}
        </List.Item>
    )
}


export const FilesList: React.FC<SelectFilesProps> = (props) => {
    const { onChange, onlyFolders, multipleSelect } = props;

    const [selectedFolder, setSelectedFolder] = useState(props.folders != null && props.folders.length > 0 ? props.folders[0] : null); // может быть лучше бы сделать массивом?
    const [basePath, setBasePath] = useState(props.basePath || (selectedFolder != null ? selectedFolder.path : "/"));

    const [currentPath, setCurrentPath] = useState(basePath); // может быть лучше бы сделать массивом?
    const [selectedFiles, setSelectedFiles] = useState<ExternalFile[]>(props.selectedItems ?? []);
    const { token } = theme.useToken()

    const { data, loading } = useYandexDiskGetFolderItemsQuery({
        variables: {
            path: currentPath,
            attachedFolderId: selectedFolder ? selectedFolder.id : null,
            onlyFolders: onlyFolders ?? false
        }
    })

    const items = data?.yandexDiskGetFolderItems as ExternalFile[];

    const breadCrumbButtonStyle: CSSProperties = {
        color: token.colors.font.primary,
        opacity: 0.5,
        padding: '4px 8px',
        height: 24,
        fontWeight: 400
    }

    const breadcrumbItems = [{
        title: <Button style={breadCrumbButtonStyle}
            type={"link"}
            styles={{ icon: { height: 16 } }}
            onClick={() => setCurrentPath(basePath)}
            icon={<IconHome fontSize={16} strokeWidth={1.5} />} />
    }]
        .concat(currentPath.replace(basePath, "").split("/").filter(v => v != "").map(i => (
            {
                title: <Button type={"link"}
                    style={breadCrumbButtonStyle}
                    onClick={() => {
                        const p = currentPath.substring(0, currentPath.indexOf(i) + i.length);
                        setCurrentPath(p)
                    }}>{i}</Button>
            })));

    return (<div>
        <Spinner loading={loading || props.loading} />
        {/*props.folders && props.folders.length > 0 && <Select
            defaultValue={basePath}
            style={{ width: "100%" }}
            onChange={(id) => {
                if (!props.folders) return;
                const folder = props.folders.find(v => v.id == id)
                if (!folder) return;
                setSelectedFolder(folder);
                setBasePath(folder.path);
                setCurrentPath(folder.path);
            }}
            options={props.folders.map(p => ({ value: p.id, label: p.path }))}
        />*/}
        <ConfigProvider theme={{
            components: {
                Breadcrumb: {
                    separatorMargin: 0
                },
                Checkbox: {
                    colorPrimary: token.colors.ui.succes,
                    colorPrimaryHover: token.colors.ui.succes
                }
            },

        }}>
            {breadcrumbItems.length > 1 && <Breadcrumb
                style={{
                    backgroundColor: token.colors.ui.bgLight,
                    borderRadius: 4,
                    width: 'fit-content',
                    height: 24,
                    alignItems: 'center',
                    marginLeft: isMobile ? 16 : 24,
                    marginBottom: isMobile ? 16 : 12

                }}
                separator={<span style={{ display: 'inline-flex', height: '100%', alignItems: 'center', opacity: 0.6 }}>•</span>} items={breadcrumbItems} />
            }
            <List
                dataSource={items}
                renderItem={(item) => <FilesListRow item={item}
                    selected={selectedFiles.some(f => f.path == item.path)}
                    selectable={props.selectable}
                    onClick={() => {
                        if (item.type == ExternalFileType.Dir)
                            setCurrentPath(item.path)
                    }}
                    onSelectionChange={(event) => {
                        event.stopPropagation();
                        event.preventDefault();

                        let res = [...selectedFiles];
                        if (event.target.checked && !selectedFiles.some(f => f.path == item.path)) {
                            if (multipleSelect)
                                res.push(item)
                            else
                                res = [item]
                        }

                        if (!event.target.checked)
                            res = selectedFiles.filter(f => f.path != item.path)

                        setSelectedFiles(res)
                        if (onChange)
                            onChange(res)

                    }} />}

            />
        </ConfigProvider>
    </div>)
}

const ButtonStyled=styled(Button)<{color: string, bgColor: string, bgColorHovered: string}>`
        flex-grow: 1;
    border-radius: 8px;
        font-weight: 300;
        border: none;
        box-shadow: none;
        height: 44px;
        background-color: ${({bgColor})=>bgColor} !important;
        color: ${({color})=>color} !important;
        width: calc(50% - 4px);
        transition: all .3s ease-in-out;

        &:hover{
            background-color: ${({bgColorHovered})=>bgColorHovered} !important;
        }
`

const AttachFilesButton: React.FC<SelectFilesProps & {
    anchor: React.RefObject<HTMLElement>
    centerJustify: CenterJustify
}> = (props) => {
    const { t } = useTranslation()
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selected, setSelected] = useState(props.selectedItems ?? []);
    const {token}=theme.useToken()

    let { title = t("file.attachFolderHeader") } = props
    let { buttonTitle } = props
    if (props.selectable && (selected?.length ?? 0) > 0)
        title += ` (${selected?.length})`



    const handleOk = () => {

        if (props.onFinish) props.onFinish(selected)
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const modalTitle = t('file.attachFilesFromFolder', { folderName: props.folders ? props.folders[0].name : '' })

    return (<>
        {isModalOpen && <Dropdowned
            title={modalTitle}
            opened={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            anchor={props.anchor}
            backdrop centerJustify={props.centerJustify}>
            <div style={{ padding: isMobile ? '16px 0px' : '24px 0px',
                width: isMobile ? 'auto' : 717,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                maxHeight: '100%' }}>
                {!isMobile && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: isMobile ? '0px 16px 16px 16px' : '0px 24px 16px 24px' }}>
                    <span style={{ fontSize: 16, fontWeight: 600 }}>{modalTitle}</span>
                    <Button
                        style={{ width: 24, height: 24, border: 'none', boxShadow: 'none', color: 'inherit' }}
                        styles={{ icon: { display: 'flex', alignContent: 'center' } }}
                        icon={<IconClose style={{ fontSize: 24, strokeWidth: 1.5 }} />}
                        onClick={() => setIsModalOpen(false)}
                    />
                </div>}
                <div style={{
                    overflowY: 'auto',
                    height: 297
                }}>
                <FilesList {...props} onChange={(items) => {
                    setSelected(items)
                    if (props.onChange) props.onChange(items)
                }} />
                </div>
                <div style={{
                    padding: isMobile? '0px 16px' : '0px 24px',
                    display: 'flex',
                    gap: 8,
                    marginTop: 16
                }}>
                    <ButtonStyled
                    color={token.colors.font.white}
                    bgColor={token.colors.ui.accent}
                    bgColorHovered={token.colors.ui.accentHover}
                    onClick={()=>{setIsModalOpen(false)}}
                    >
                        {t('file.attach')}</ButtonStyled>
                    <ButtonStyled
                    color={token.colors.font.primary}
                    bgColor={token.colors.ui.bgLight}
                    bgColorHovered={token.colors.ui.bgLight3}
                    onClick={()=>setIsModalOpen(false)}
                    >{t('cancel')}</ButtonStyled>
                </div>
            </div>
        </Dropdowned>}
        <Button {...props.btnProps} icon={<IconFiles fontSize={16} strokeWidth={1.5} />}
            onClick={() => {
                setIsModalOpen(true)
            }}
        >{buttonTitle}</Button></>)
}

export default AttachFilesButton