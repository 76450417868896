import React, {type SVGProps} from "react"

export function IconFiles(props: SVGProps<SVGSVGElement>) {
   
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1"
             strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=files, size=24">
        <g id="Frame">
            <path id="Vector"
                  d="M19.8651 11.7185L12.2068 19.3768C11.2686 20.315 9.9961 20.8421 8.66929 20.8421C7.34246 20.8421 6.06999 20.315 5.13179 19.3768C4.19358 18.4386 3.6665 17.1661 3.6665 15.8393C3.6665 14.5125 4.19358 13.24 5.13179 12.3018L12.7901 4.64348C13.4156 4.01801 14.2639 3.66663 15.1484 3.66663C16.033 3.66663 16.8813 4.01801 17.5068 4.64348C18.1323 5.26895 18.4837 6.11727 18.4837 7.00181C18.4837 7.88636 18.1323 8.73468 17.5068 9.36014L9.84012 17.0185C9.52739 17.3312 9.10322 17.5069 8.66095 17.5069C8.21868 17.5069 7.79452 17.3312 7.48179 17.0185C7.16905 16.7057 6.99335 16.2816 6.99335 15.8393C6.99335 15.397 7.16905 14.9729 7.48179 14.6601L14.5568 7.59348"
                  stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
    </g>
</svg>

    )
}
