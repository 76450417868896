import React, {type SVGProps} from "react"

export function IconCommentHistory(props: SVGProps<SVGSVGElement>) {
   
    return (
        <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1"
             strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=comment+history, size=16">
        <g id="Group 48">
            <path id="Vector 16"
                  d="M13 6V13.5C13 14.0523 12.5523 14.5 12 14.5H3C2.44772 14.5 2 14.0523 2 13.5V2.5C2 1.94772 2.44772 1.5 3 1.5H7.5"
                  stroke="currentColor"/>
            <path id="Vector 15" d="M4.5 2L4.5 14" stroke="currentColor" strokeLinecap="round"/>
        </g>
        <g id="Group 43">
            <path id="Vector 11" d="M8.45605 6V6.91704C8.45605 7.16106 8.73187 7.30299 8.93043 7.16117L10.4386 6.08392"
                  stroke="currentColor"/>
            <path id="Vector 12"
                  d="M8.5 6.16431C8.5 6.16431 8.02079 6.16431 7.50003 6.16431C7.22389 6.16431 7 5.94046 7 5.66432V1C7 0.723858 7.22386 0.5 7.5 0.5H13.8636C14.1398 0.5 14.3636 0.723858 14.3636 1V5.66432C14.3636 5.94046 14.1398 6.16431 13.8636 6.16431H10.3986"
                  stroke="currentColor" strokeLinecap="round"/>
        </g>
    </g>
</svg>

    )
}
