import React, {type SVGProps} from "react"

export function IconCopyLink(props: SVGProps<SVGSVGElement>) {
   
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1"
             strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=copy link, size=24">
        <g id="_&#195;&#144;&#194;&#161;&#195;&#144;&#194;&#187;&#195;&#144;&#194;&#190;&#195;&#144;&#194;&#185;_1"
           clipPath="url(#clip0_203_27574)">
            <path id="Vector"
                  d="M11.2524 6.18948L13.684 3.7579C15.3998 2.04211 18.2524 2.12632 20.063 3.93685C21.8735 5.74737 21.9577 8.6 20.2419 10.3158L17.8103 12.7474"
                  stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round"/>
            <path id="Vector_2"
                  d="M12.6103 17.9474L10.2524 20.3053C8.58926 21.9684 5.77873 21.8421 3.9682 20.0316C2.15768 18.221 2.03136 15.4105 3.69452 13.7474L6.05241 11.3895"
                  stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round"/>
            <path id="Vector_3" d="M7.66309 16.1369L16.1683 7.63159" stroke="currentColor" strokeMiterlimit="10"
                  strokeLinecap="round"/>
        </g>
    </g>
    <defs>
        <clipPath id="clip0_203_27574">
            <rect width="20" height="20" fill="white" transform="translate(2 2)"/>
        </clipPath>
    </defs>
</svg>

    )
}
