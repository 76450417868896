import React, {type SVGProps} from "react"

export function IconDelete(props: SVGProps<SVGSVGElement>) {
   
    return (
        <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fillOpacity="1"
             strokeOpacity="1" {...props} stroke="currentColor">
    <g id="name=delete, size=24">
        <g id="_&#208;&#160;&#208;&#142;&#208;&#160;&#194;&#187;&#208;&#160;&#209;&#149;&#208;&#160;&#226;&#132;&#150;_1"
           clipPath="url(#clip0_192_13112)">
            <g id="Group">
                <path id="Vector"
                      d="M4.94141 4.68582H19.0591V20.7036C19.0591 21.6566 18.2708 22.4259 17.2943 22.4259H6.70611C5.72964 22.4259 4.94141 21.6566 4.94141 20.7036V4.68582Z"
                      stroke="currentColor" strokeMiterlimit="10"/>
                <path id="Vector_2" d="M2.58838 4.68582H21.4119" stroke="currentColor" strokeMiterlimit="10"
                      strokeLinecap="round"/>
                <path id="Vector_3"
                      d="M9.64717 1.57413H14.3531C15.0001 1.57413 15.5295 2.09083 15.5295 2.72235V4.68582H8.4707V2.72235C8.4707 2.09083 9.00011 1.57413 9.64717 1.57413Z"
                      stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round"/>
            </g>
            <g id="Group_2">
                <path id="Vector_4" d="M9.57666 9.17535V18.0626" stroke="currentColor" strokeMiterlimit="10"
                      strokeLinecap="round"/>
                <path id="Vector_5" d="M14.4238 9.17535V18.0626" stroke="currentColor" strokeMiterlimit="10"
                      strokeLinecap="round"/>
            </g>
        </g>
    </g>
    <defs>
        <clipPath id="clip0_192_13112">
            <rect width="20" height="22" fill="white" transform="translate(2 1)"/>
        </clipPath>
    </defs>
</svg>

    )
}
