import React, {ReactElement, useState} from "react";
import {Space, theme} from "antd";
import {AuditOutlined, LoginOutlined, PhoneOutlined, UserAddOutlined, WalletOutlined,} from "@ant-design/icons";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {LeftSider} from "./LeftMenu.styled";
import {ButtonsContainer, ButtonWrapper} from "./LeftMenuButtons/LeftMenuButtons.styled";
import {LeftMenuProps} from "./LeftMenu";

interface MenuButtonProps {
    collapsed: boolean
    title: string
    path: string
    icon: ReactElement
}

export const MenuButton: React.FC<MenuButtonProps> = (props) => {
    const navigate = useNavigate();
    const {token} = theme.useToken();

    return <Space direction={"vertical"} style={{width: '100%'}}>
        <ButtonWrapper $token={token} collapsed={props.collapsed ? "true" : undefined}
                       onClick={() => navigate(props.path)}>
            {props.icon} {!props.collapsed && <p>{props.title}</p>}
        </ButtonWrapper>
    </Space>;
}

export const LeftMenuUnauthorized: React.FC<LeftMenuProps> = ({collapsed, setCollapsed}) => {
    const {t} = useTranslation();
    const {token} = theme.useToken();

    const [isMd, setMd] = useState<boolean>(false) //изменяется на true - false при изменении ширины экрана.

    // const showCollapseButton = !isMobile;

    return <LeftSider
        $token={token}
        breakpoint="md"
        width={isMd ? "100%" : 300}
        collapsedWidth={isMd ? "0" : "72"}
        trigger={null}
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        onBreakpoint={(broken) => setMd(broken)}
    >
        {/*{showCollapseButton && <LeftMenuCollapseButton collapsed={collapsed} isMd={isMd} setCollapsed={setCollapsed}/>}*/}

        <div className="left-menu">
            <ButtonsContainer collapsed={collapsed ? "true" : undefined} $token={token}>
                <Space direction={"vertical"} style={{width: '100%'}}>
                    <MenuButton title={t('auth.signIn')} icon={<LoginOutlined/>} collapsed={collapsed}
                                path={'/signin'}/>
                    <MenuButton title={t('auth.signUp')} icon={<UserAddOutlined/>} collapsed={collapsed}
                                path={'/signup'}/>
                    <MenuButton title={t('tariffsTable.title')} icon={<WalletOutlined/>} collapsed={collapsed}
                                path={'/tariffs'}/>
                    <MenuButton title={t('termsOfUse')} icon={<AuditOutlined/>} collapsed={collapsed}
                                path={'/termsofuse'}/>
                    <MenuButton title={t('contacts')} icon={<PhoneOutlined/>} collapsed={collapsed} path={'/contacts'}/>
                </Space>
            </ButtonsContainer>
        </div>
    </LeftSider>
}