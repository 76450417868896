import {Badge, Divider, List, message, Tooltip} from 'antd';
import React, {useState} from 'react';
import {Task, TasksView, useTaskSaveMutation} from "../../generated-types";
import {useTranslation} from 'react-i18next';
import Tagger from "../Tagger/Tagger";
import dayjs from 'dayjs';
import TaskMembers from "./TaskMembers";
import TaskResolved, {ResolvedCard} from "./TaskResolved";
import TaskTitle from "./TaskTitle";
import TaskResolvedBlur from "./TaskResolvedBlur";
import {taskToTaskInput} from './TasksBoardItem';

const dateFormat = 'DD.MM';

const TasksFastListItem: React.FC<{task: Task, taskView: TasksView}> = ({task}) => {
    const {t} = useTranslation();
    const [hovered, setHovered] = useState(false);

    const showDates = task.startDate || task.endDate;

    const datesInfo =
        (task.startDate ? dayjs(task.startDate).format(dateFormat) : "")
        + (task.startDate && task.endDate ? "-" : "") +
        (task.endDate ? dayjs(task.endDate).format(dateFormat) : "");

    const [taskSave] = useTaskSaveMutation();
    const onTagDelete=(tags: string[])=>{
        taskSave({
            variables: {
                id: task.id,
                task: taskToTaskInput(task, tags)
            },
            onError: (error, clientOptions)=> {
                
                message.error(t('tags.tagDelitionErrorMessage'))
            },
        })
    }
    const item = <List.Item style={ResolvedCard(task.resolved,{position: "relative", padding: "6px 0"})}>
        <List.Item.Meta
            title={<div
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}>
            {!hovered && <TaskResolvedBlur task={task} />}
                {/*{<TaskImportance value={task.importance} id={task.id} key={task.importance}/>}*/}
                <TaskResolved
                    id={task.id}
                    disabled={task.project.archived||task.project.paymentAccount?.isBlocked}
                    value={task.resolved}
                    key={""+task.resolved}
                    small />
                <TaskTitle task={task} />
                {task.tags && <Tagger projectId={task.project.id} defaultValue={task.tags} onCloseTag={onTagDelete}/>}
            </div>}
        />

        <TaskMembers task={task} />
    </List.Item>;

    if (showDates){
        let today = dayjs().startOf("day")
        let deadlineOverdue = dayjs(task.endDate).diff(today, "day");

        if (deadlineOverdue == 0)
            return <Tooltip title={t('task.currentTask')}><Badge.Ribbon text={datesInfo} color="orange">{item}</Badge.Ribbon></Tooltip>
        else if (deadlineOverdue < 0)
            return <Tooltip title={t('task.deadlineOverdue')}><Badge.Ribbon text={datesInfo} color="red">{item}</Badge.Ribbon></Tooltip>
        else
            return <Tooltip title={t('task.deadlineOverdue')}><Badge.Ribbon text={datesInfo} color="#c1c1c1ad">{item}</Badge.Ribbon></Tooltip>

    }

    return item;
}

const TasksFastList: React.FC<{tasks: Task[], taskView: TasksView}> = ({tasks, taskView}) => {
    const {t} = useTranslation();

    const resolved = tasks.filter(t=>t.resolved);
    const notResolved = tasks.filter(t=>!t.resolved);

    return (
        <div>
            <List
                itemLayout="horizontal"
                dataSource={notResolved}
                renderItem={(task) => (
                    <TasksFastListItem key={task.id} task={task} taskView={taskView} />
                )}
            />
            <Divider>{t('resolvedTasks')}</Divider>
            <List
                itemLayout="horizontal"
                dataSource={resolved}
                renderItem={(task) => (
                    <TasksFastListItem key={task.id} task={task} taskView={taskView} />
                )}
            />
        </div>
    )
};

export default TasksFastList;
